<template>
    <div id="order_car-container">
      <div class="show-map" @click="mapAlertShow">
        <ShowMap class="map" :points="mapPoints"></ShowMap>
      </div>
      <div class="choose-car-container">
        <div class="order-car-title">
          {{ $t('common.Select car series') }}
        </div>
        <div class="car-list-container">
          <div class="car-list">
            <div class="car-item" v-for="(item,index) in  carList" :class="carIndex == index ? 'active' : ''" @click="chooseOtherCar(index)">
              <div class="car-img">
                <img :src="item.image">
              </div>
              <div class="car-series-name">{{item.series}}</div>
              <div class="car-price">
                <div class="tag-icon">{{item.tag[0]}}</div>
                ￥{{item.price}}
              </div>
              <div class="choose-box">
                <label class="label-z">
                  <input type="checkbox" :checked="carIndex == index ? 'checked' : ''" class="check-inp-z" @click="chooseOtherCar(index)">
                </label>
              </div>
            </div>

            <div class="clear-info" :class="carList.length <= 0 ? '' : 'hidden'">{{$t('service.no More Sereis')}}</div>
          </div>
        </div>

        <div class="bottom-btn">
          <div class="mini-btn-list-container">
            <div class="mini-btn" @click="pickerShow = true">
              {{$t('common.user time')}}:{{dateTime}}
              <img :src="icon.arrowIcon" class="right-arrow-img">
            </div>
            <div class="mini-btn" @click="showOtherPassenger = true">
              {{$t(passenger)}}
              <img :src="icon.arrowIcon" class="right-arrow-img">
            </div>
          </div>
          <div class="sub-btn" :class="lang" @click="subCreateOrder">{{ $t('common.confirm') }}</div>
        </div>
      </div>

      <!--      修改乘车人-->
      <van-popup id="wx-otherPassenger" v-model="showOtherPassenger" :close-on-click-overlay="false">
        <div class="wx-otherPassenger-container">
          <div class="close-btn">
            <img class="x-icon" :src="icon.close" @click="showOtherPassenger = false"/>
          </div>
          <div class="op-title">{{ $t('service.welcome to use the function') }}</div>
          <div class="op-form">
            <div class="op-input" :class="lang">
              <input :placeholder="$t('service.Please enter phone number')" v-model="pMobile" maxlength="11">
            </div>
            <div class="op-history-list">
              <div class="op-history-item-box" v-for="(item,index) in pList">
                <div class="op-history-item" @click="choosePassenger(index)">{{item}}</div>
              </div>
            </div>
          </div>
          <div class="mini-tips">{{ $t('common.Drivers will prioritize contacting passengers') }}</div>
          <div class="sub-btn" @click="subPassenger">{{ $t('common.confirm') }}</div>
        </div>
      </van-popup>

      <!--      弹窗-->
      <w-x-time-picker :pickerShow='pickerShow' @pickerShowClose="pickerShow=false" @confirm="onConfirm"></w-x-time-picker>

      <ChooseHotelMap :class="mapShow ? '': 'hidden'" :show="mapShow"  :outHotelId='hotelId' @confirm="onConfirm2" @closeAlert="closeAlert"></ChooseHotelMap>

      <van-overlay :show="loadShow"  class="overlay-div">
        <van-loading type="spinner"  class="load"/>
      </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast, DatetimePicker,Checkbox} from 'vant';
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(DatetimePicker).use(Checkbox);
    import ShowMap from '@/components/ShowMap.vue';
    import WXTimePicker from "@/components/WXTimePicker.vue";
    import ChooseHotelMap from "@/components/ChooseHotelMap.vue";
    import {loginWXType} from "@/config/utils";
    export default {
      name: 'transfer',
      components:{
        ChooseHotelMap,
      ShowMap,WXTimePicker
      },
        data() {
            return {
              loadShow : false,
              mapShow : false,
              icon: {
                arrowIcon : '/images/service/icon-park-right@3x.png',
                close: '/images/icon/if-close 1@3x.png'
              },
              pickerShow: false,
              dateTime: '--/-- --:--',
              dateTimeStamp : 0,
              showOtherPassenger: false,
              pList : [],
              passenger: 'service.passenger',
              passengerMobile :  '',
              pMobile: '',
              carList: [],
              carIndex: 0,
              itemId : 0,
              type : 0,
              hotelId: 0,
              lonlat: '',

              origin: '',
              end : '',
              origin_position: '',
              end_position: '',
              mapPoints: '',
              lang : '',
            }
        },
        created: function () {
          var query = this.$route.query;
          if(!this.$isset(query.item_id)){
            this.$router.push('/index')
          }

          this.itemId = query.item_id;
          if(this.itemId == 39 || this.itemId == 40 ||this.itemId == 41){
            this.type = (query.type == null || query.type == 0 || query.type == undefined)? 1 : query.type;
          }

          this.origin = this.$store.getters['order/getStart'];
          this.end = this.$store.getters['order/getEnd'];
          this.origin_position = this.$store.getters['order/getStartPoint'];
          this.end_position = this.$store.getters['order/getEndPoint'];

          if(this.itemId == 26){
           this.mapPoints = this.end_position;
          }else{
            this.mapPoints = this.origin_position;
          }

          this.dateTimeStamp = this.$store.getters['order/getTime'];
          this.dateTime = this.$timeFormat(this.dateTimeStamp);
          this.passengerMobile = this.$store.getters['user/getMobile'];
          var pListStr = this.$store.getters['local/getPList'];
          if(this.$isset(pListStr)){
            this.pList = pListStr.split(',');
          }
          this.hotelId = Number(this.$store.getters['order/getHotelId']);

          this.lang = this.$store.getters['local/getLanguages'];
          this.getCarSereis();
        },
        mounted() {
          if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL)
            window.addEventListener('popstate', this.back, false)
          }
        },
        destroyed() {
          window.removeEventListener('popstate', this.back, false)
          return false;
        },
        methods: {
          back(){
            if(this.mapShow){
              this.mapShow = false;
            }else{
              this.$store.commit('order/setItemId', this.itemId);
              this.$router.back();
            }
          },
          /**
           * 载入车系
           */
          getCarSereis(){
            var that = this;
            this.loadShow = true;
            this.$post('/getCarSeries', {
              hotelid : this.hotelId,
              itemid : this.itemId,
              cartype : this.type,
              'origin' : this.origin,
              'origin_position' : this.origin_position,
              'end' : this.end,
              'end_position' : this.end_position,
            }).then((res)=>{
              if(res.code == 1000){
                var list = [];
                var data = res.data;
                for (var i =0; i < data.length; i++){
                  var item = {
                        'id' : data[i]['id'],
                        'image' : data[i]['image'],
                        'tag' : data[i]['tag'],
                        'series' : data[i]['name'],
                        'price' : data[i]['fee']
                      }
                      if(this.lang == 'en'){
                        item['series'] = data[i]['name_en']
                      }
                      list.push(item);
                }

                this.carList = list;
              }else{
                Toast(res.msg);
                setTimeout(function(){
                  that.$router.push({
                    path: '/index'
                  });
                }, 500)
              }
            }).finally(()=>{
              this.loadShow = false;
            })
          },
          chooseOtherCar: function(index){
            this.carIndex = index;
          },
          choosePassenger(index){
            this.pMobile = this.pList[index];
          },
          subPassenger(){
            this.passengerMobile = this.pMobile == '' ? this.$store.getters['user/getMobile'] : this.pMobile;
            this.passenger = this.pMobile == '' ? 'service.passenger' : this.pMobile;
            this.showOtherPassenger = false;
            this.pList.push(this.pMobile);
            this.pList = [...new Set(this.pList)];
            if(this.pList.length > 2){
              this.pList.shift(1);
            }
            this.$store.commit('local/setPList', this.pList.toString());
          },
          /**
           * 更新时间
           * @param e
           */
          onConfirm: function(e){
            this.$store.commit('order/setTime', e);
            this.dateTimeStamp = new Date(e).getTime();
            this.dateTime = this.$timeFormat(this.dateTimeStamp);
            this.pickerShow = false;
          },
          changeMap: function(){

            this.$emit('changeMapInfo', info);
          },
          mapAlertShow(){
            this.mapShow = true;
          },
          onConfirm2(e){
            this.hotelId = e.hotelid;
            //处理类型起始点
            this.startendPoingtChange(e);
            this.mapShow = false;
          },
          startendPoingtChange(e){
            if(this.itemId == 26){
              //接机
              this.end = e.location;
              this.end_position = e.position;
              this.mapPoints = e.position;
            }else{
              //送机 包车从酒店出发
              this.origin = e.location;
              this.origin_position = e.position;
              this.mapPoints = e.position;
            }
            this.getCarSereis();
          },
          closeAlert(){
            this.mapShow = false;
          },
          /**
           * 提交订单
           */
          subCreateOrder(){
            var that = this;

            if(this.carList.length <= 0){
              Toast('无可用车系');
              return false;
            }

            var city = this.$store.getters['order/getCity'];
            var seriesId = this.carList[this.carIndex]['id'];
            var flightNumber = this.$store.getters['order/getFlightNumber'];
            var time = this.dateTimeStamp/1000;
            var data ={
              'series_id' : seriesId,
              'uid' : this.$store.getters['user/getUserId'],
              'itemid' : this.itemId,
              'hotelid' : this.hotelId,
              'appointment' : time,
              'origin' : this.origin,
              'origin_position' : this.origin_position,
              'end' : this.end,
              'end_position' : this.end_position,
              'mobile' : this.$store.getters['user/getMobile'],
              'passenger_name' : this.passengerMobile,
              'passenger_phone' : this.passengerMobile,
              'flight_number' : this.$isset(flightNumber) ? flightNumber : '',
              'remarks' : '',
              'city' : city,
              'pid' : this.$store.getters['user/getPid'],
              'lang' : this.$store.getters['local/getLanguages']
            }

            this.$post('/submitUserOrder', data
            ).then((res)=>{
              if(res.code == 1000){
                var orderId = res.data.orderid;
                that.$router.replace({
                  path : '/order_detail?id=' + orderId
                })
              }else{
                Toast(res.msg+'/接口');
              }
            }).catch((e)=> {
              Toast(e +'/接口异常');
            }).finally(()=>{
                this.loadShow = false;
            });
          }
        }
    }
</script>

<style>
  #order_car-container{
    width: 100%;
  }
  #order_car-container .choose-car-container{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 70% ;
    background: linear-gradient(rgba(255,255,255,1) 0%,rgba(255,255,255,0.8) 50%);
    border-radius: 25px 25px 0 0;
    padding-bottom: env(safe-area-inset-bottom);
    z-index: 20;
  }

  #order_car-container .choose-car-container .order-car-title{
    color: #B98A73;
    font-size: 20px;
    text-align: center;
    width: 100%;
    height: 50px;
    padding: 10px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid #BBBBBB;
  }
  #order_car-container .choose-car-container .car-list-container{
    margin-top: 50px;
    height: 100%;
    overflow: scroll;
    padding-bottom: calc(env(safe-area-inset-bottom) + 100px);
    z-index: 30;
  }

  #order_car-container .choose-car-container .car-list-container .car-item{
    height: 100px;
    border-bottom: 1px solid #BBBBBB;
    font-size: 16px;
    color: #B98A73;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: left;
    padding: 0 20px;
    align-items: center;
  }
  #order_car-container .choose-car-container .car-list-container .car-item.active{
    background: linear-gradient(-90deg, #FFF7F3 0% , rgba(255,255,255,0.7) 80%);
  }
  #order_car-container .choose-car-container .car-list-container .car-item .car-img{
    width : 20%;
  }
  #order_car-container .car-list-container .car-item .car-img img{
    width: 100%;
  }
  #order_car-container .choose-car-container .car-list-container .car-item .car-series-name{
    width: 30%;
  }
  #order_car-container .choose-car-container .car-list-container .car-item .car-price{
    white-space: nowrap;
    width: 40%;
    line-height: 16px;
  }
  #order_car-container .choose-car-container .car-list-container .car-item .tag-icon{
    font-size: 12px;float: left;
  }
  #order_car-container .choose-box{
    width: 20px;
  }
  #order_car-container .choose-box .label-z{
    position: relative;
    display: block;
    text-align: left;
    padding-left: 30px;
    color: #B98A73;
    font-size: 12px;
  }
  #order_car-container .choose-box .label-z .agreement{
    color: #7C7CD5;
    display: inline;
  }
  #order_car-container .choose-box .check-inp-z{
    width: 16px;
    height: 16px;
    border: 2px solid #B98A73;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    appearance: none; /* none时可以自定义其外观 */
  }
  #order_car-container .choose-box .check-inp-z:after{
    content: '';
    width: 10px;
    height: 10px;
    background-color: #B98A73;
    border-radius: 3px;
    position: absolute;
    top: 1px;
    left: 1px;
    transform: scale(0);
    transition: all 0.2s ease-in-out;
  }
  #order_car-container .choose-box .check-inp-z:checked:after{
    transform: scale(1);
  }
  #order_car-container .choose-box .check-inp-z:hover,.check-inp-z:checked{
    border-color: #B98A73;
  }


  #order_car-container .choose-car-container .bottom-btn{
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    background: #fff;
    box-shadow: 0 0 8px rgba(187, 187, 187, 0.73);
    z-index: 1000;
  }
  #order_car-container .choose-car-container .mini-btn-list-container{
    padding: 6px 0;
    height: 30px;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    line-height: 30px;
    box-sizing: content-box;
  }
  #order_car-container .choose-car-container .mini-btn-list-container .mini-btn{
    width: 50%;
    position: relative;
  }
  #order_car-container .choose-car-container .mini-btn .right-arrow-img{
    position: absolute;
    right: 30px;
    height: 10px;
    width: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #order_car-container .choose-car-container .mini-btn:nth-child(1) .right-arrow-img{
    right: 15px;
  }
  #order_car-container .choose-car-container .mini-btn img{
    width: 100%;
    height: 100%;
  }
  #order_car-container .choose-car-container .mini-btn-list-container .mini-btn:nth-child(1){
    border-right: 1px solid #BBBBBB;
  }
  #order_car-container .choose-car-container .bottom-btn .sub-btn{
    height: 50px;
    line-height: 50px;
    letter-spacing: 8px;
    background: #B98A73;
    color: #fff;
  }
  #order_car-container .choose-car-container .bottom-btn .sub-btn.en{
    letter-spacing: 0;
  }

  #order_car-container .wx-otherPassenger-container{
    background: #fff;
    padding: 20px 30px;
    width: 329px;
    position: relative;
    background: linear-gradient(180deg, #D7B6A5 0%, #fff 50%);
  }
  #order_car-container .wx-otherPassenger-container .close-btn{
    width: 12px;
    height: 12px;
    position: absolute;
    top: 12px;
    right: 12px;
  }
  #order_car-container .wx-otherPassenger-container .close-btn img{
    width: 100%;
  }
  #order_car-container .wx-otherPassenger-container .op-title{
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 30px;
  }
  #order_car-container .wx-otherPassenger-container .op-form{
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px #000;
    padding: 10px 20px;
  }
  #order_car-container .wx-otherPassenger-container .op-input{
    background: #F0EEEE;
    font-size: 20px;

    padding: 0 4px;
    border-radius: 8px;
    line-height: 36px;
  }
  #order_car-container .wx-otherPassenger-container .op-input.en{
    font-size: 14px;
  }
  #order_car-container .wx-otherPassenger-container .op-input input{
    border: none;
    background: transparent;
    text-align: left;
    width: 100%;
  }
  #order_car-container .wx-otherPassenger-container .op-history-list{
    line-height: 28px;
    padding: 20px  0;
    height: 68px;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
  }
  #order_car-container .wx-otherPassenger-container .op-history-list .op-history-item-box{
    width: 50%;
  }
  #order_car-container .wx-otherPassenger-container .op-history-list .op-history-item{
    box-shadow: 0px 2px 6px #000;
    text-align: center;
    border-radius: 6px;
    width: 100px;
    margin: auto;
  }
  #order_car-container .wx-otherPassenger-container .mini-tips{
    font-size: 14px;
    color: #101010;
    text-align: left;
    line-height: 40px;
  }
  #order_car-container .wx-otherPassenger-container .sub-btn{
    background: #000;
    border-radius: 4px;
    color: #fff;
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
  }

  #order_car-container .hidden{
    display: none;
  }

  #order_car-container .car-list-container .clear-info{
    position: absolute;
    text-align: center;
    height: 100px;
    line-height: 100px;
    width: 100%;
    color: #BBB;
  }
</style>
