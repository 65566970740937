<template>
    <div class="index" id="index-container">
      <div class="tree-btn">
        <img class="tree-img" :src="icon.tree" @click="alertRightList">
      </div>
      <div class="index-banner-list">
        <img class="index-img index-banner"  :src="banner.img">
      </div>
      <div class="btn-list">
        <div class="btn-list-title">
          <div class="btn-line"></div>
          {{$t('index.Exclusive Services')}}</div>
        <div class="index-btn" @click="navToTransfer">
          <img v-if="language=='en'" class="index-img " :src="cardImg.transfer.en">
          <img v-else class="index-img " :src="cardImg.transfer.zh">
        </div>
        <div class="index-btn index-btn2" @click="navToCharter">
          <img v-if="language=='en'" class="index-img" :src="cardImg.charter.en">
          <img v-else class="index-img" :src="cardImg.charter.zh">
        </div>
      </div>


      <!-- 个人中心选择 --->
      <van-popup id="right-list-alert" v-model="rightListShow" position="right">
        <div class="right-list-container">
          <div class="close-btn">
            <img :src="icon.close" @click="rightListShow = false">
          </div>
          <div class="language-c" @click="changeLanguage">中文/English</div>
          <div class="right-list">
            <div class="right-item" @click="navTolist">
              <img class="item-img" :src="icon.list">
              {{ $t('common.order list') }}</div>
          </div>

          <div class="logout-item"  @click="logout">
            <div class="logout-item-line">
              <div class="logout-item-container">
                <img class="item-img" :src="icon.logout">
                {{ $t('common.logout') }}
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Toast} from 'vant';
    vue.use(Button).use(Overlay).use(Loading);
    export default {
        name: 'login',
        data() {
            return {
              icon:{
                list: '/images/icon/icon-bottom-list2.png',
                tree: '/images/icon/icon-index-tre.png',
                close: '/images/icon/icon-index-right.png',
                logout: '/images/icon/icon-index-logout.png'
              },
              banner:{
                img: '/images/index2/index-banner.jpg'
              },
              cardImg : {
                transfer:{
                  zh: '/images/index2/card_transfer_zh.jpg',
                  en : '/images/index2/card_transfer_en.jpg'
                },
                charter: {
                  zh: '/images/index2/card_charter_zh.jpg',
                  en : '/images/index2/card_charter_en.jpg'
                }
              },
              rightListShow :  false,
              hotelBanner: ''
            }
        },
        created: function () {
          var qrCode = this.$store.getters['local/getQrCode'];
          if(this.$isset(qrCode)){
            this.bindQrCode(qrCode);
          }else{
            this.setHotelImg();
          }
        },
        methods: {
          navToTransfer(){
            this.$router.push({
              path: '/transfer'
            })
          },
          navToCharter(){
            this.$router.push({
              path: '/charter'
            })
          },
          /**
           * 绑定qrcode
           */
          bindQrCode(qrCode){
            var userId = this.$store.getters['user/getUserId'];
            var qrCode = this.$store.getters['local/getQrCode'];
            this.$post('/decrypt', {
              qrcode : qrCode,
              uid : userId
            }).then((res)=>{
              if(res.code == 1000){
                this.$store.commit('user/setPid', res.data.pid);
                this.$store.commit('user/sethotelId', res.data.hotelid);

              }
            }).finally(()=>{
              this.setHotelImg();
            })
            //销毁
            this.$store.commit('local/delQrCode');
          },

          //酒店id获取酒店logo
          setHotelImg(){
            //有无hotelid
            var hotelid = this.$store.getters['user/getHotelId'];
            if(!this.$isset(hotelid)){
              //无
              return false;
            }
            //更新酒店图
            this.$post('/getHotelAddress', {
              hotelid : hotelid
            }).then((res)=>{
              if(res.code == 1000){
                if(that.$isset(res.data.hotelimg)){
                  this.hotelBanner = res.data.hotelimg;

                  return false;
                }
                //其他不做处理
              }
            }).catch((e)=>{
              console.log(e.msg);
            })
          },
          /**
           * 变更个中英文
           */
          changeLanguage: function(){
            if(this.language != 'zh'){
              this.language = 'zh';
            }else{
              this.language = 'en';
              this.$store.commit('local/setLanguages')
            }
            this.$i18n.locale = this.language;
            this.$store.commit('local/setLanguages',this.language);
          },
          navTolist(){
            this.$router.push('/order_list')
          },
          alertRightList(){
            this.rightListShow = true;
          },
          logout(){
            this.$store.commit('user/delAll')
            this.$router.replace('/login');
          }
        }
    }
</script>

<style>
    .alert-container{
        display: flex;
    }
    #index-container{
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding-bottom: env(safe-area-inset-bottom);
      background: linear-gradient(90deg, #FAFAFC 0, #E3E4E6 100%);
      position: relative;
    }
    #index-container img{
      width: 100%;
      margin: auto;
    }
    #index-container .index-banner-list{
      position: relative;
    }
    #index-container .btn-list{
      padding: 0 24px;
      padding-top: 27px;
    }
    #index-container .btn-list .btn-list-title{
      text-align: left;
      letter-spacing: 2px;
      color: #000000;
      position: relative;
      padding-top: 6px;
    }
    #index-container .btn-list .btn-line{
      width: 15px;
      height: 2px;
      background: #B98A73;
      position: absolute;
      top: 0;
      left: 0;
    }
    #index-container .index-btn{
      height: 35%;
      position: relative;
      width: 100%;
      border-radius: 30px;
      overflow: hidden;
      margin-top: 24px;
    }
    #index-container .index-btn2{
      margin-top: 48px;
    }
    #index-container .list-btn{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      z-index: 10;
      background: #faf6f6;
      padding: 10px;
      border-radius: 50px;
      box-shadow: 0px 2px 6px #000;
    }
    #index-container .list-btn img{
      width: 100%;
      height: 100%;
    }
    #index-container .tree-btn{
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      z-index: 10;
      padding: 10px;
      border-radius: 50px;
    }
    #index-container .tree-btn img{
      width: 100%;
      height: 100%;
    }

    #index-container #right-list-alert{
      height: 100%;
      width: 46%;
      font-size: 14px;
    }
    #index-container .right-list-container{
      height: 100%;
      background: #fff;
      position: relative;
      padding-top: 40px;
    }

    #index-container .right-list-container .right-list-container img{
      width: 100%;
      height: 100%;
    }

    #index-container .language-c{
      position: absolute;
      right: 10px;
      top: 0;
      height: 40px;
      line-height: 40px;
      z-index: 100;
      font-size: 12px;
    }

    #index-container .right-list-container .close-btn{
      position: absolute;
      left: 10px;
      top: 10px;
      height: 20px;
      width: 20px;
    }

    #index-container .right-item{
      border-top: 1px solid #BBB;
      height: 44px;
      line-height: 44px;
      text-align: left;
      position: relative;
      padding-left: 50px;
    }
    #index-container .right-item:last-child{
      border-bottom: 1px solid #BBB;
    }
    #index-container .right-item .item-img{
      height: 20px;
      width: 20px;
      position: absolute;
      margin: auto;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    #index-container #right-list-alert .logout-item{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      padding-bottom: env(safe-area-inset-bottom);
    }
    #index-container #right-list-alert .logout-item-line{
      border-top: 1px solid #BBB;
      border-bottom: 1px solid #BBB;
    }
    #index-container #right-list-alert .logout-item-container{
      position: relative;
      height: 40px;
      line-height: 40px;
      text-align: left;
      padding-left: 40px;
      display: inline-block;
    }
    #index-container #right-list-alert .logout-item .item-img{
      height: 20px;
      width: 20px;
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    @media (max-height: 700px){
      #index-container .btn-list{
        padding: 0 20px;
        padding-top: 14px;
      }
      #index-container .index-btn{
        margin-top: 12px;
      }
    }
</style>
