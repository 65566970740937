/**
 * 用户共享信息
*/
const user = {
    namespaced: true,
    state : {
        hotelId : 0,
        openid : '',
        token : '',
        userId : 0,
        first  : true,
        mobile  : '',
        pid: 0,
    },
    mutations: {
        setOpenId(state,openid){
            state.openid = openid;
            localStorage.openid = openid
        },
        setUserId(state,userId){
            state.userId = userId;
            localStorage.userId = userId //同步存储token至localStorage
        },
        setFirst(state, first){
            state.first = first;
        },
        setMobile(state, mobile){
            state.mobile = mobile
            localStorage.mobile = mobile //同步存储token至localStorage
        },
        sethotelId(state, hotelId){
            state.hotelId = hotelId
            localStorage.hotelId = hotelId //同步存储token至localStorage
        },
        setPid(state, pid){
            state.pid = pid
            localStorage.pid = pid //同步存储token至localStorage
        },
        delAll(state, pid){
            localStorage.clear();
            sessionStorage.clear();
        }
    },
    getters : {
        //获取token方法
        getOpenId(state){
            if (!state.openid) {
                state.openid = localStorage.getItem('openid');
            }
            if(state.openid == null) return '';
            return state.openid

        },
        getUserId(state){
            if (!state.userId) {
                state.userId = localStorage.getItem('userId');
            }
            return state.userId

        },
        getMobile(state){
            if (!state.mobile) {
                state.mobile = localStorage.getItem('mobile');
            }
            return state.mobile

        },
        getHotelId(state){
            if (!state.hotelId) {
                state.hotelId = localStorage.getItem('hotelId');
            }
            return state.hotelId
        },
        getPid(state){
            if (!state.pid) {
                state.pid = localStorage.getItem('pid');
            }
            if(state.pid == null || state.pid== ''){
                state.pid = 0;
            }
            return state.pid
        }
    },
    actions: {

    }
};

export default  user;