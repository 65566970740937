import user from "@/store/modules/user";
import {Toast} from "vant";

export function timeFormat(timeStamp ,type =1){
    timeStamp = Number(timeStamp);
    var date = new Date(timeStamp);
    var hours =(Array(2).join(0) + date.getHours()).slice(-2);
    var min =(Array(2).join(0) + date.getMinutes()).slice(-2);
    var second =(Array(2).join(0) + date.getSeconds()).slice(-2);
    if(type == 1){
        if(this.$store.getters['local/getLanguages'] == 'en'){
            return (date.getMonth() + 1) + '/' + date.getDate() + ' ' + hours + ':' +min
        }else{
            return (date.getMonth() + 1) + '月' + date.getDate() + '日 ' + hours + ':' +min
        }
    }else if(type == 2){
        return (date.getMonth() + 1) + '-' + date.getDate() + '- ' + hours + ':' +min
    }else{
        return (date.getFullYear() + '-' +date.getMonth() + 1) + '-' + date.getDate() + '- ' + hours + ':' + min + ':' + second
    }
}

export function strToTime(date){
    date = date.replace('年', '/');
    date = date.replace('月', '/');
    date = date.replace('日', '');
    date = date.replace(/\-/g, '/');
    date = new Date(date);
    return date.getTime();
}

export function isset(item){
    if(item == null || item == undefined || item == ''){
        return false;
    }else{
        return true;
    }
}

export function loginWXType(){
    //记录当前页面
    this.$store.commit('local/setUrl', this.$route.fullPath);

    var openId = this.$store.getters['user/getOpenId'];
    if(this.$isset(openId)){
        //存在通过
        var userId = this.$store.getters['user/getUserId'];
        if(this.$isset(userId)){
            //绑定关系
            this.$post('/bindUidAndOpenid', {
                uid : userId,
                openid : openId
            }).then((res)=>{
                if(res.code != 1000){
                    Toast(res.msg);
                }
            }).catch((e)=>{
                Toast(e.msg);
            })
        }
        return true;
    }else{
        //不存在-强行跳转到author认证(不留痕迹)
        this.$router.replace('/author');
        return false;
    }
}