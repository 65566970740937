import Vue from 'vue'
import Router from 'vue-router'

import login from '@/pages/index/login.vue'
import author from '@/pages/index/author.vue'
import index from '@/pages/index/index.vue'
import transfer from '@/pages/service/transfer.vue'
import charter from '@/pages/service/charter.vue'
import order_car from '@/pages/order/car.vue'
import order_list from '@/pages/order/list.vue'
import order_detail from '@/pages/order/detail.vue'
import service_zh from '@/pages/info/service_zh.vue'
import service_en from '@/pages/info/service_en.vue'

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: login,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/author',
            name: 'author',
            component: author,
            children: [
                {path: '*'}
            ]
        },
        {
            path: '/transfer',
            name: 'transfer',
            component: transfer,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '接送机';
                next();
            }
        },
        {
            path: '/charter',
            name: 'charter',
            component: charter,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '包车';
                next();
            }
        },
        {
            path: '/order_car',
            name: 'order_car',
            component: order_car,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '车系选择';
                next();
            }
        },
        {
            path: '/order_list',
            name: 'order_list',
            component: order_list,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '订单列表';
                next();
            }
        },
        {
            path: '/order_detail',
            name: 'order_detail',
            component: order_detail,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '订单详情';
                next();
            }
        },
        {
            path: '/service_zh',
            name: 'service_zh',
            component: service_zh,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '用户协议';
                next();
            }
        },
        {
            path: '/service_en',
            name: 'service_en',
            component: service_en,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '用户协议';
                next();
            }
        },
        {
            path: '/',
            name: 'index',
            component: index,
            children: [
                {path: '*'}
            ],
            beforeEnter: (to, from, next) => {
                //动态修改title
                document.getElementById('titleId').innerHTML = '豪华礼宾车';
                next();
            }
        },
        {path: '*', redirect: '/index'}
    ],
    mode: "history",
    base: process.env.VUE_APP_SRC,
})