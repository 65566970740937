<template>
    <div id="service_en">

      <div>This APP User Agreement (hereinafter referred to as "this Agreement") is an agreement between you and our company
        (Parklane Travel) regarding the use of various services and related matters of this APP.
      </div>
      <div><br></div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>In order to use the services of this APP, you should carefully read and comply with all the contents of this usage
        agreement, especially the clauses related to exemption or liability limitation. Unless you have read and accepted
        all the terms of this usage agreement,
      </div>
      <div><br></div>
      <div>Otherwise, you will not be able to use the services of this APP. If you do not agree to any content of this usage
        agreement, please do not use the services of this APP. If you have any doubts about the content of this user
        agreement (especially clauses related to exemptions or limitations of liability),
      </div>
      <div><br></div>
      <div>You can contact us at any time according to the contact information listed in this usage agreement, and we will
        further explain and explain the relevant content according to your requirements. If you checked "I agree to the APP
        User Agreement"
      </div>
      <div><br></div>
      <div>By using or accepting any services of this APP through registration procedures or any other means, it is deemed
        that you have read and agreed to all the terms of this usage agreement, and voluntarily accept all the contents of
        this usage agreement.
      </div>
      <div><br></div>
      <div>Please confirm again that you are aware and fully understand all the contents of this usage agreement before
        deciding to use the service.
      </div>
      <div><br></div>
      <div>If you are under the age of 18, please be sure to read this service agreement with your parents and other legal
        guardians, and before registering, placing orders, making payments, publishing information, or using any other
        services of this app,
      </div>
      <div><br></div>
      <div>Obtain the consent of your parents and other legal guardians in advance. Once you complete the registration process
        and become a user of this APP (hereinafter referred to as "the user/you"), it is deemed that you fully understand
        and accept all the terms of this usage agreement.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>"This APP" refers to the collective term for the relevant entities that operate or provide the specific services
        you use by Parklane Travel (the APP operating entity). "User" refers to the user who uses the services related to
        this APP, and is more commonly referred to as "you" in this usage agreement.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.1、 Confirmation and acceptance of service terms</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. This app has the right to modify and update these terms of use as necessary in accordance with relevant national
        policies and the owner of the app's operational needs or the purpose of improving service quality, and to notify you
        through reasonable and prominent means such as announcements and prompt messages sent by mobile clients,
      </div>
      <div><br></div>
      <div>The above modifications and updates will be implemented from the date specified in the relevant update
        instructions.
      </div>
      <div><br></div>
      <div>2. You should promptly check and understand the relevant updates and modifications. If you do not agree with the
        relevant updates and modifications, you may stop using the services involved in the updates and modifications. In
        this case, the changes will not be effective for you; If you implement the above updated and modified content,
      </div>
      <div><br></div>
      <div>Continuing to use the services involved shall be deemed as your agreement and acceptance of the revised terms of
        use.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.2、 Account registration</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. This app is a travel software that requires account registration and is free to use</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.3、 Basic Code of Conduct and Responsibility for Users</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. You are aware and promise to strictly abide by national laws, regulations, and ethical standards in the process
        of using the services provided by this app, and not to engage in any illegal activities using this app.
      </div>
      <div><br></div>
      <div>2. Users are not allowed to publish or disseminate information containing the following content on this app:</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>(1) Harming national security, leaking state secrets, subverting state power, and undermining national unity;</div>
      <div><br></div>
      <div>(2) Harming national honor and interests;</div>
      <div><br></div>
      <div>(3) Inciting ethnic hatred, discrimination, and undermining ethnic unity;</div>
      <div><br></div>
      <div>(4) Disrupting national religious policies, promoting cults and feudal superstitions;</div>
      <div><br></div>
      <div>(5) Spreading rumors, obscenity, pornography, gambling, violence, murder, terror, or instigating crimes;</div>
      <div><br></div>
      <div>(6) Insult, defame others, or infringe upon the legitimate rights of others;</div>
      <div><br></div>
      <div>(7) Inciting illegal assemblies, associations, marches, demonstrations, or gatherings to disrupt social order;
      </div>
      <div><br></div>
      <div>(8) Other content that violates laws, regulations, rules, regulations, and social moral norms that are restricted
        or prohibited.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>3. The information you publish should also comply with national policies and social ethics, respect the legitimate
        rights of others such as reputation, portrait, intellectual property, and trade secrets;
      </div>
      <div><br></div>
      <div>4. You should not copy or use unpublished and unauthorized files on the network without authorization. Except with
        the explicit consent of the relevant copyright owner, one shall not crack, disseminate, download or copy software
        owned by third parties or engage in any other activities that infringe upon the intellectual property rights of
        others in the network without authorization;
      </div>
      <div><br></div>
      <div>5. You shall not interfere with or infringe upon the normal operation of this app or the normal use of this app by
        other users.
      </div>
      <div><br></div>
      <div>6. If you engage in one or more of the above behaviors during the use of this APP service, you will be held legally
        responsible for your actions. The forms of assuming legal responsibility include but are not limited to:
        compensating the victim, and assuming the first responsibility for your actions on this APP
      </div>
      <div><br></div>
      <div>After the administrative penalty or liability for infringement damages caused (including litigation fees, lawyer
        fees, and other related legal process fees), you should immediately provide equal compensation to the owner of this
        APP.
      </div>
      <div><br></div>
      <div>7. Based on the illegal situation, this APP has the right to make independent judgments and immediately suspend or
        cancel some or all of the services provided to you, including measures such as locking, canceling, and deleting
        accounts.
      </div>
      <div><br></div>
      <div>8. The system records of this app will serve as evidence of user violation of the law.</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.4、 Introduction to this app's services and user registration information</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. This app provides users with the following services:</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>(1) Users can provide services such as airport pick-up and drop off, car rental, etc. on this app;</div>
      <div><br></div>
      <div>(2) Use various tools provided by this app, such as selecting a car model, selecting a flight, selecting a hotel,
        etc.
      </div>
      <div><br></div>
      <div>(3) Other free and open service items to users, or upgrading existing service items</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.5、 Storage and limitations of user information</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. Users should properly store their personal information, and this app is not responsible for any storage failure
        or loss of information posted by users.
      </div>
      <div><br></div>
      <div>2. This app has the right to determine whether the user's behavior and published information comply with laws,
        regulations, national policies, and the provisions of these service terms, and to delete suspected illegal content
        without notifying the user.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.6、 Intellectual Property</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>The intellectual property rights of the website name, client, registered trademark copy, images, and all data and
        information generated during the operation and maintenance process of this APP belong to this APP. Without the
        consent of the owner of this APP, it is prohibited to display, use or otherwise process the above information and
        materials in any way.
      </div>
      <div><br></div>
      <div>You shall comply with relevant laws and regulations and shall not infringe upon the intellectual property rights of
        this APP, APP merchants, and other third parties. This app has the right to determine whether the user's behavior
        and published information comply with laws, regulations, national policies, and the provisions of these service
        terms, and to delete suspected illegal content without notifying the user.
      </div>
      <div><br></div>
      <div>If you believe that the relevant content of this APP has infringed your intellectual property rights, you can
        notify this APP in writing in accordance with relevant laws and provide evidence of the infringement of relevant
        rights. If your notification causes damage to the legitimate rights and interests of others, you shall be liable for
        compensation in accordance with the law, and this APP shall not be liable for this.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.7、 Information protection</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>This app will strictly fulfill the user's personal privacy and confidentiality obligations, promise not to
        disclose, edit or disclose user personal information, protect the user information you submit, and will not disclose
        it to any unrelated third party, except for the following situations:
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>(1) The user authorizes this app to disclose this information;</div>
      <div><br></div>
      <div>(2) Necessary for providing relevant services;</div>
      <div><br></div>
      <div>(3) Corresponding laws and legal procedures require the holder of this app to provide the user's personal
        information;
      </div>
      <div><br></div>
      <div>(4) In emergency situations, strive to maintain the safety needs of individual users, other individuals in society,
        and the general public.
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.8、 Disclaimer clause</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. Any content sent or published by users through this app or the services provided by this app shall not reflect
        or represent, nor shall it be deemed to reflect or represent the views, positions or policies of this app, and this
        app shall not be held responsible for this.
      </div>
      <div><br></div>
      <div>2. When this app recommends content from other websites in the form of links, it does not reflect or represent that
        this app is responsible for the relevant content or holds biased views. The owner of this app also does not
        guarantee the authenticity and legality of any content, products, services or other materials obtained from other
        websites,
      </div>
      <div><br></div>
      <div>This app shall not be liable for any direct or indirect losses caused (or claimed to be caused) by the use or
        reliance on content, products, services, or other materials obtained from such websites or resources.
      </div>
      <div><br></div>
      <div>3. This app is not responsible for any personal data leakage, loss, theft, tampering, or inability to function
        properly caused by force majeure factors such as hacker attacks, computer virus intrusion or outbreak, or temporary
        shutdowns caused by government regulations that affect the normal operation of the network.
      </div>
      <div><br></div>
      <div>4. This app reserves the right to modify or interrupt services at any time without notifying the user. The holder
        of this app has the right to modify or interrupt services without being responsible to users or third parties.
      </div>
      <div><br></div>
      <div>5. If the user of this app violates the provisions of this statement and violates the laws and regulations of the
        People's Republic of China, they shall be responsible for all consequences, and the owner of this app shall not be
        held responsible.
      </div>
      <div><br></div>
      <div>6. Other situations that cannot be controlled or reasonably encountered by this app.</div>
      <div><br></div>
      <div>7. The refund plan shall be formulated by the company based on operations, and the final interpretation right shall
        be determined by the company
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>NO.9、 Termination of Agreement</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>If any of the following situations occur, this APP has the right to terminate the free or paid services provided to
        users without being liable to users or any third party:
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>(1) The user violates relevant laws and regulations or the provisions of this agreement;</div>
      <div><br></div>
      <div>(2) According to legal provisions or requirements of competent administrative authorities;</div>
      <div><br></div>
      <div>(3) For safety reasons or other necessary circumstances.</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div>NO.10、Refund terms</div>
      <div><br></div>
      <div>&nbsp;1.If the order is returned 2 hours（excluding 2 hours） before the start , can be exempted from liability and refunded</div>
      <div><br></div>
      <div>&nbsp;2.If the order is returned 1-2 hours（including 2 hours） before the start, a 50% fee will be charged as a dispatch fee</div>
      <div><br></div>
      <div>&nbsp;3.If the order is returned 1 hour（including 1 hours） before the start, a 100% fee will be charged as a dispatch fee</div>
      <div><br></div>
      <div>NO.11、 Legal application and jurisdiction</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1. The conclusion, execution, interpretation, and disputes of this agreement shall be governed by the laws of the
        People's Republic of China.
      </div>
      <div><br></div>
      <div>2. If there is any dispute between the two parties regarding the content of the agreement or its execution, both
        parties shall try their best to resolve it through friendly consultation. If consultation fails, the user and this
        APP agree to submit it to the jurisdiction of the court with jurisdiction in the location where the APP operator is
        located.
      </div>
      <div><br></div>
      <div>3. The failure of this APP to exercise or enforce any rights or provisions of this agreement does not constitute a
        waiver of the aforementioned rights or provisions.
      </div>
      <div><br></div>
      <div><br></div>
    </div>
</template>

<script>
    export default {
        name: 'service_en',
        data() {
            return {}
        },
        created: function () {
        },
        methods: {}
    }
</script>

<style>
#service_en div{
  text-align: left;
  font-size: 14px;
}
</style>
