<template>
  <div id="choose-hotel-map">
    <div class="map">
      <div class="map-abbreviation">
        <div class="map-abbreviation-content">{{hotelItem['abbreviation']}}</div>
      </div>
      <div id="map-core"></div>
      <div class="hotel-list-container">
        <div class="close-btn" @click="closeAlert">
          <img :src="icon.close">
        </div>
        <div class="hotel-list-title">{{ $t('service.hotel') }} <div class="city-btn" @click="changeCityAlert">{{ $t(cityName) }}<span>({{$t('service.choose city')}})</span></div></div>
        <div class="hotel-list">
          <div class="hotel-item" v-for="(item,index) in  hotelList"  :class="hotelId == item.hotelid ? 'active' : ''" >
            <div class="hotel-item-container"  @click="chooseOtherHotel(item.hotelid)">
              <div class="hotel-icon">
                <img class="hotel-img" :src="icon.hotel">
              </div>
              <div class="hotel-name">{{item['abbreviation']}}</div>
              <div class="choose-box">
                <label class="label-z">
                  <input type="checkbox" :checked="hotelId == item.hotelid ? 'checked' : ''" class="check-inp-z" @click="chooseOtherHotel(item.hotelid)">
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-btn" @click="chooseHotel">{{ $t('common.confirm') }}</div>
      </div>
    </div>


    <!-- 城市选择-->
    <van-popup v-model="cityPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
      <van-picker
          :title="$t('common.Please choose city')"
          :confirm-button-text =  "$t('common.confirm')"
          :cancel-button-text = "$t('common.cancel')"
          show-toolbar
          :columns="cityList"
          value-key = "cityName"
          @cancel="cityPicker = false"
          @confirm="onLocalConfirm"
      ></van-picker>
    </van-popup>
  </div>
</template>

<script>
    /* eslint-disable */
    import {Toast} from "vant";
    import xCoordYellowIcon from '@/assets/X-coord-yellow.png'
    import xCoordRedIcon from '@/assets/X-coord-red.png'

    export default {
        name: 'ChooseHotelMap',
      props: {
        show:{
          type: Boolean,
          default: false
        },
        outHotelId: {
          type: Number,
          default: 0
        },
      },
      data () {
          return {
            visible:true,
            location: {
                lng: '',
                lat: '',
                key: '',
                province: ''
            },
            map: {},
            ac: {},
            hotelList: [],
            hotelId: 0,
            hotelItem : {
              abbreviation : '-'
            },
            icon: {
              xCoordRed : '/images/icon/X-coord-red.png',
              xCoordYellow : '/images/icon/X-coord-yellow.png',
              hotel: '/images/icon/icon-hotel-black.png',
              close: '/images/icon/if-close 1@3x.png'
            },
            cityId: this.$store.getters['local/getCityId'],
            cityCode: this.$store.getters['local/getCityCode'],
            cityName: 'service.shanghai',
            cityPicker : false,
            cityList : []
          }
      },
      mounted () {
        //直接用IP定位
        var that = this;
        function myFun(result){
          that.setMap(result.center.lng,result.center.lat);
        }

        //城市定位
        var myCity = new BMap.LocalCity();

        myCity.get(myFun);
        },
      created () {
      },
      methods: {
        // 初始化地图
        setMap (lng, lat) {
          var that = this;
          function G(id) {
              return document.getElementById(id);
        }
        // 禁止地图旋转和倾斜可以通过配置项进行设置
        this.map = new BMap.Map('map-core',{
          enableRotate: false,
          enableTilt: false
        });
        this.map.centerAndZoom(new BMap.Point(lng, lat), 14);
        this.map.enableScrollWheelZoom(true);
        //特殊样式（出行）
        this.map.setMapStyleV2({
          styleId: '6f4d31a48560b5e53befd599f4c861fa'
        })

        this.loadCityHotel();
        },
        mapDrawPoint(hotelId = this.hotelId){
          // console.log(hotelId);
          var that = this;
          if(this.hotelList.length > 0){
            var iconYellow = new BMap.Icon(xCoordYellowIcon, new BMap.Size(46, 50),{
              // 指定定位位置。
              // 当标注显示在地图上时，其所指向的地理位置距离图标左上
              // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
              // 图标中央下端的尖角位置。
              anchor: new BMap.Size(20, 50),
              imageSize: new BMap.Size(46, 50)
            });
            var iconRed = new BMap.Icon(xCoordRedIcon, new BMap.Size(46, 50),{
              // 指定定位位置。
              // 当标注显示在地图上时，其所指向的地理位置距离图标左上
              // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
              // 图标中央下端的尖角位置。
              anchor: new BMap.Size(20, 50),
              imageSize: new BMap.Size(46, 50)
            });
            this.map.clearOverlays() //清空覆盖物
            for (var i = 0; i < this.hotelList.length; i++){
              var item = this.hotelList[i];

              var point = new BMap.Point(item.lat, item.lon);
              if(item.hotelid ==hotelId){
                // console.log(item);
                //选中的
                this.map.centerAndZoom(point, 14); //设置为中心
                var marker = new BMap.Marker(point,{icon: iconRed});
                this.map.addOverlay(marker);

                this.hotelItem = item;
              }else{
                var marker = new BMap.Marker(point,{icon: iconYellow});
                this.map.addOverlay(marker);
              }
              marker.addEventListener("click", function(item){
                return function (){
                  that.markClick(item)
                }
              }(item));
            }
          }
        },
        markClick(item){
          // console.log('您点击了标注')
          var hotelid = item.hotelid
          this.hotelId = item.hotelid;
          this.mapDrawPoint(hotelid);
        },
        chooseOtherHotel(hotelid){
          this.hotelId = hotelid;
          this.mapDrawPoint(this.hotelId);
        },
        // 向父组件传递经纬度
        selectLocation () {
            this.$emit('selectLocation', this.location)
        },
        closeAlert(){
            this.$emit('closeAlert', {})
        },
        selectValue: function(e){
            // e.currentTarget.select();
        },
      /**
       * 载入城市酒店
       */
      loadCityHotel(){
        var lang = this.$store.getters['local/getLanguages'];
            this.$post('/getCityHotel', {
              'company_id' : this.cityId
            }).then((res)=>{
              if(res.code == 1000){
                if(res.data.length > 0 && lang == 'en'){
                  for (var i=0; i<res.data.length; i++){
                    res.data[i]['abbreviation'] = res.data[i]['name_en']
                  }
                }
                this.hotelList = res.data;
                this.hotelId = this.outHotelId;

                this.mapDrawPoint(this.hotelId);
              }else{
                Toast(res.msg);
              }
            }).catch((e)=>{
              Toast(e.msg)
            })
      },
        /**
         * 载入城市
         */
      loadCityList(){
          return this.$post('/getCityCode', {
            'lang' : this.$store.getters['local/getLanguages']
          });
      },
      chooseHotel(){
        //触发成功(返回时间戳)
        this.$emit('confirm', this.hotelItem);
      },
      changeCityAlert(){
          // Toast(this.$i18n.t('common.city Temporarily Closed'));
        var that = this;
        this.loadCityList().then((res)=>{
          if(res.bizCode == 1000){
            this.cityList = res.data
            this.cityPicker =true;
          }else{
            Toast(res.msg);
          }
        }).catch((e)=>{
          Toast(e.msg)
        })

      },
      onLocalConfirm(e){
        this.cityName = e.cityName;
        this.cityCode = e.cityCode;
        this.cityId = e.company_id;

        this.$store.commit('local/setCityCode', this.cityCode);
        this.$store.commit('local/setCityId', this.cityId);

        this.loadCityHotel();
        this.cityPicker = false;
      }
    },
      watch: {
        show(now, old){
          this.mapDrawPoint(this.hotelId);
        },
        outHotelId(now, old){
          this.hotelId = now;
          this.mapDrawPoint(this.hotelId);
        },
      }
  }
</script>

<style scoped>
#choose-hotel-map{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
}

#choose-hotel-map .map-abbreviation{
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  width: 80%;
  margin: auto;
  z-index: 200;
  background: #fff;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #7a7979;
}

#choose-hotel-map .map{
  width: 100%;
  height: 100%;
  padding-bottom: calc( env(safe-area-inset-bottom) + 200px) ;
}
#choose-hotel-map #map-core{
  width: 100%;
  height: 100%;
}

#choose-hotel-map .hotel-list-container{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 20px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding-bottom: env(safe-area-inset-bottom);
  background: #fff;
  box-shadow: 0 2px 6px #00020e;
}
#choose-hotel-map .hotel-list-container .hotel-list-title{
  text-align: left;
  padding: 0 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #8A8A8C;
  font-weight: bold;
}

#choose-hotel-map .hotel-list-container .hotel-list-title .city-btn{
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
}
#choose-hotel-map .hotel-list-container .hotel-list-title .city-btn span{
  color: #bcbcbc;
}
#choose-hotel-map .hotel-list{
  height: 260px;
  overflow: scroll;
  padding-bottom: 20px;
}
#choose-hotel-map .hotel-list .hotel-item{
  padding: 0 30px;
  border-bottom: 1px solid #8A8A8C;
  padding-left: 72px;
  height: 40px;
  line-height: 40px;
  position: relative;
  font-size: 14px;
  text-align: left;
}
#choose-hotel-map .hotel-list .hotel-item.active{
  background: linear-gradient(-90deg, #FFF7F3 0% , rgba(255,255,255,0.7) 80%);
}
#choose-hotel-map .hotel-item .hotel-item-container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

#choose-hotel-map .hotel-list .hotel-item .hotel-icon{
  width: 24px;
  height: 24px;
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  margin: auto;
}
#choose-hotel-map .hotel-list .hotel-item .hotel-icon img{
  width: 100%;
  height: 100%;
}


#choose-hotel-map .choose-box{
  width: 20px;
}
#choose-hotel-map .choose-box .label-z{
  position: relative;
  display: block;
  text-align: left;
  padding-left: 30px;
  color: #B98A73;
  font-size: 12px;
}
#choose-hotel-map .choose-box .label-z .agreement{
  color: #7C7CD5;
  display: inline;
}
#choose-hotel-map .choose-box .check-inp-z{
  width: 16px;
  height: 16px;
  border: 2px solid #B98A73;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  appearance: none; /* none时可以自定义其外观 */
}
#choose-hotel-map .choose-box .check-inp-z:after{
  content: '';
  width: 10px;
  height: 10px;
  background-color: #B98A73;
  border-radius: 3px;
  position: absolute;
  top: 1px;
  left: 1px;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}
#choose-hotel-map .choose-box .check-inp-z:checked:after{
  transform: scale(1);
}
#choose-hotel-map .choose-box .check-inp-z:hover,.check-inp-z:checked{
  border-color: #B98A73;
}

#choose-hotel-map .sub-btn{
  background: #B98A73;
  border-radius: 4px;
  color: #fff;
  height: 40px;
  line-height: 40px;
}

#choose-hotel-map .close-btn{
  position: absolute;
  top: 10px;
  right: 20px;
  height: 20px;
  width: 20px;
}
#choose-hotel-map .close-btn img{
  width: 100%;
  height: 100%;
}
</style>