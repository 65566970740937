<template>
    <div id="service_zh">
      <div>本《APP用户使用协议》（以下简称“本使用协议”）是您与本公司（威信出行）就本APP使用本APP各项服务等相关事宜所订立的协议。
      </div>
      <div><br></div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>
        为了使用本APP的服务，您应当仔细阅读并遵守本使用协议下的全部内容，特别是涉及免除或责任限制的条款。除非您已阅读并接受本使用协议所有条款，
      </div>
      <div><br></div>
      <div>
        否则您将不能使用本APP的服务，如果您不同意本使用协议的任意内容，请勿使用本APP的服务。如果您对本用户协议的内容（特别是涉及免除或责任限制的条款）有任何疑义，
      </div>
      <div><br></div>
      <div>
        可随时按照本使用协议中列明的联系方式与我们联系，我们将根据您的要求为您进一步解释和说明相关内容。如果您勾选了“我同意《APP用户使用协议》”
      </div>
      <div><br></div>
      <div>
        并通过注册程序或其他任何方式使用或接受本APP的任何服务，即视为您已经阅读并同意本使用协议的所有条款，自愿接受本使用协议的所有内容的约束。
      </div>
      <div><br></div>
      <div>请您在决定使用服务前再次确认您已经知悉并完全理解本使用协议的所有内容。</div>
      <div><br></div>
      <div>
        如果您未满18周岁，请务必在家长和其他法定监护人的陪同下阅读本服务协议，并在进行注册、下单、支付、发布信息等任何行为或使用本APP的其他任何服务前，
      </div>
      <div><br></div>
      <div>
        应事先征得您的家长和其他法定监护人的同意。您一旦完成注册程序成为本APP使用用户（以下称“用户/您”），视为完全了解并接受本使用协议的全部条款。
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>
        “本APP”是指威信出行（APP运营主体）运营或提供您所使用的相关具体服务的相关主体的统称。“用户”是指使用本APP相关服务的使用人，在本使用协议中更多称呼为“您”。
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>一、服务条款的确认和接纳</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>
        1.本app有权根据国家相关政策和app归属方根据运营需要或提升服务质量的目的，在必要时对本使用条款进行修改更新，并通过移动客户端发出公告、提示信息等合理、醒目的方式向您进行通知，
      </div>
      <div><br></div>
      <div>上述修改更新内容将在相关更新说明中指定的日期开始实施。</div>
      <div><br></div>
      <div>
        2.您应当及时查询并了解相关更新修改内容，如您不同意相关更新修改内容，可停止使用相关更新修改内容所涉及的服务，此情形下，变更事项不对您产生效力；如您在上述更新修改内容实施后，
      </div>
      <div><br></div>
      <div>继续使用所涉及的服务，视为您已同意并接受变更后的使用条款。</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>二、账号的注册</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1.本APP为出行软件,需账号注册,免费使用</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>三.用户基本行为准则及责任承担</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1.您悉知并承诺，在使用本APP所提供的服务过程中严格遵守国家法律、法规以及道德规范，不得使用本app从事任何违法活动。</div>
      <div><br></div>
      <div>2.用户不得在本app上发布、传播含有下列内容的信息：</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>（1）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
      <div><br></div>
      <div>（2）损害国家荣誉和利益的；</div>
      <div><br></div>
      <div>（3）煽动民族仇恨、民族歧视、破坏民族团结的；</div>
      <div><br></div>
      <div>（4）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div><br></div>
      <div>（5）散布谣言、淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
      <div><br></div>
      <div>（6）侮辱、诽谤他人，或侵害他人合法权利的；</div>
      <div><br></div>
      <div>（7）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</div>
      <div><br></div>
      <div>（8）其他违反法律、法规、规章、条例以及社会道德规范所限制或禁止的内容的。</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>3.您所发布信息亦应符合国家的政策、社会公德，尊重他人名誉权、肖像权、知识产权、商业秘密等合法权利；</div>
      <div><br></div>
      <div>
        4.您不应擅自复制或使用网络上未公开和未授权的文件。除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动；
      </div>
      <div><br></div>
      <div>5.您不得干扰或侵犯本app的正常运行、其他用户对本app的正常使用。</div>
      <div><br></div>
      <div>
        6.如您在使用本APP服务过程中涉及上述及上述行为中的一项或多项，则需要对自己的行为承担法律责任。承担法律责任的形式包括但不限于：对受害者进行赔偿，以及在本APP首先承担了因您的行
      </div>
      <div><br></div>
      <div>为导致的行政处罚或侵权损害赔偿责任等损失（含诉讼费、律师费等相关法律程序费用）后，您应立即给予本APP所有人等额的赔偿。
      </div>
      <div><br></div>
      <div>7.根据违法情形，本APP有权作出独立判断并有权立即暂停或取消对您提供的部分或全部服务，其措施包括锁定、注销、删除账号等。
      </div>
      <div><br></div>
      <div>8.本app的系统记录将作为用户违反法律的证据。</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>四、本app服务简介及用户注册信息</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1.本app向用户提供如下服务：</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>（1）用户可以在本APP进行接送机，包车等服务；</div>
      <div><br></div>
      <div>（2）使用本APP提供的各种工具，如选定车型,选定航班,选定酒店等。</div>
      <div><br></div>
      <div>（3）其他对用户免费开放的服务项目，或升级已有的服务项目</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>五、用户信息的存储和限制</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1.用户应妥善保存个人所发信息，本app不对用户所发布信息的储存失败或丢失负责。</div>
      <div><br></div>
      <div>
        2.本app有权判断用户的行为、所发布信息是否符合法律、法规、国家政策及本服务条款之规定，并对涉嫌违规内容自行删除而无须通知该用户。
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>六、知识产权</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>&nbsp;
        &nbsp;本APP网站名称、客户端、注册商标文案、图片、运营维护过程中所产生的所有数据和信息的知识产权均为本APP所有。未经本APP所有人同意，禁止将以上信息与素材以任何方式展示、使用或做其他处理。
      </div>
      <div><br></div>
      <div>&nbsp;
        &nbsp;您应当遵守相关法律法规，不得侵犯本APP、APP商家及其他第三方的知识产权。本app有权判断用户的行为、所发布信息是否符合法律、法规、国家政策及本服务条款之规定，并对涉嫌违规内容自行删除而无须通知该用户。
      </div>
      <div><br></div>
      <div>&nbsp;
        &nbsp;若您认为本APP的相关内容侵犯了您的知识产权，您可以依据相关法律规定，书面通知本APP，并提供相关权利侵害证据。如因您的通知导致他人的合法权利受到损害，您应依法承担赔偿责任，本APP对此不承担责任。
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>七、信息保护</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>
        本app将严格履行用户个人隐私保密义务，承诺不公开、编辑或透露用户个人信息，对您所提交的用户信息予以保护，不会将其披露给人以非关联第三方，但以下情况除外：
      </div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>（1）用户授权本app透露这些信息；</div>
      <div><br></div>
      <div>（2）为提供相关服务所必须的；</div>
      <div><br></div>
      <div>（3）相应的法律及法律程序要求本app持有者提供用户的个人资料；</div>
      <div><br></div>
      <div>（4）在紧急情况下，竭力维护用户个人、其他社会个体和社会大众的安全需要。</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>八、免责条款</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>
        1.用户通过本APP或本APP提供的服务发送、发布的任何内容不反应和代表，也不得被视为反应或代表本APP的观点、立场或政策，本APP对此不承担任何责任。
      </div>
      <div><br></div>
      <div>
        2.本app以链接形式推荐其他网站内容时，并不反应或代表本app对相关内容负责以及持有倾向性看法，本APP持有者亦不保证从其他网站获取的任何内容、产品、服务或其他材料的真实性、合法性，
      </div>
      <div><br></div>
      <div>
        对于任何因使用或信赖从此类网站或资源上获取的内容、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，本app均不承担责任。
      </div>
      <div><br></div>
      <div>
        3.任何由于黑客攻击、计算机病毒侵入或发作、因政府管制而造成的暂时性关闭等影响网络正常经营的不可抗力而造成的个人资料泄露、丢失、被盗用、被窜改或不能正常使用，本app均不负责。
      </div>
      <div><br></div>
      <div>4.本app保留随时修改或中断服务而无须知照用户的权利。本APP持有者行使修改或中断服务的权利，不需对用户或第三方负责。</div>
      <div><br></div>
      <div>5.本app使用者因为违反本声明的规定而触犯中华人民共和国法律法规的，一切后果自己负责，本APP持有者不承担任何责任。</div>
      <div><br></div>
      <div>6.其他本APP无法控制或合理遇见的情形。</div>
      <div><br></div>
      <div>7.关于退款方案由公司根据运营制定，最终解释权由公司确定</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>九、协议终止情形</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>如发生下列任何一种情形，本APP有权终止向用户提供的免费服务或收费服务，而无须对用户或任何第三方承担责任：</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>（1） 用户违反相关法律法规规定或本协议的规定；</div>
      <div><br></div>
      <div>（2） 按照法律规定或有权行政机关的要求；</div>
      <div><br></div>
      <div>（3） 出于安全原因或其他必要情形。</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>十、退款条款</div>
      <div><br></div>
      <div>&nbsp;1.订单开始前2小时(不含2小时)可以免责退款</div>
      <div><br></div>
      <div>&nbsp;2.订单开始前1-2小时(含2小时)退单，将收取50%的费用作为调度费用</div>
      <div><br></div>
      <div>&nbsp;3.订单开始前1小时(含1小时)内退单，将收取100%的费用作为调度费用</div>
      <div><br></div>
      <div>十一、法律适用、管辖</div>
      <div><br></div>
      <div>&nbsp;</div>
      <div><br></div>
      <div>1.本协议的订立、执行和解释及争议均适用中国中华人民共和国法律。</div>
      <div><br></div>
      <div>
        2.如双方就协议内容或其执行发生任何争议，双方应尽量友好协商解决，协商不成时，用户和本APP一致同意提交本APP运营商所在地有管辖权的法院管辖。
      </div>
      <div><br></div>
      <div>3.本APP未行使或执行本协议任何权利或规定，不构成对前述权利或权利之放弃。</div>
      <div><br></div>
      <div><br></div>
    </div>
</template>

<script>
    export default {
        name: 'service_zh',
        data() {
            return {}
        },
        created: function () {
        },
        methods: {}
    }
</script>

<style>
#service_zh div{
  text-align: left;
  font-size: 14px;
}
</style>
