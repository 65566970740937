module.exports = {
    common : {
        phone : 'Phone',
        verificationCode : 'Verification Code',
        submit: 'Submit',
        'Please enter your phone number' : 'Cell-phone number',
        'Please enter your code' : 'Enter verification code',
        'Please enter code' : 'Please enter code',
        'get code' : 'code',
        'confirm' : 'confirm',
        'cancel' : 'cancel',
        'service agreement' : 'Confirm the User Agreement',
        'this service is provided by ParkLane' : 'this service is provided by ParkLane ',
        'Drivers will prioritize contacting passengers' : 'Drivers will prioritize contacting passengers',
        'Select car series':'car series',
        'cancel order' : 'cancel',
        'to pay' :'to pay',


        'ordernumber':'ordernumber',
        'Hotel phone' : 'Hotel phone',
        'Customer phone' :'Customer phone',
        '¥' : '¥',
        'city Temporarily Closed' : 'Temporarily Closed',
        'Please choose city' : 'Please choose city',

        'order list' : 'order list',
        'logout' : 'logout',
        'user time' : 'usage time'
    },
    index: {
        'Please login first' : 'Please login first',
        'Login now' : 'Login Now',
        'Exclusive Services' : 'Exclusive Services'
    },
    service: {
         'welcome to use the function' : 'welcome to use the function',
        'pick-up': 'pick-up',
        'drop-off' : 'drop-off',
        'professional and reassuring services' :'professional and reassuring services',
        'Please enter flight number' :'Please enter flight number',
        'Please enter your end' : 'Please enter your dest',
        'Starting from' : 'Starting from',
        'go on' : '',
        'select airport' : 'Select airport',
        'select date' : 'Select date',
        'select time' : 'Select date',
        'luxury car' :'luxury car',
        'business Van' :'business Van',
        'our 24-hour service line' : '24-hour service line',
        'hotel' : 'hotel',
        '(local time)' : '(local time)',
        'Please enter phone number' :'Please enter phone number',
        'passenger' :'passenger',
        '2 hours' : '2 hours',
        '4 hours' : '4 hours',
        '8 hours' : '8 hours',
        'shanghai' : 'shanghai',
        'choose city' : 'choose city',
        'no More Sereis' : 'no More Sereis'
    },
    order : {
        'time' : 'time',
        'start' : 'start',
        'start2' : 'start',
        'end' : 'dest',
        'end2' : 'dest',
        'service type' : 'service type',
        'car series' : 'car series',
        'flight number' : 'flight number',
        'Cancel successful' : 'Cancel successful',
        'can rebook' : '',
        'phone service' : 'phone service',
        'no more' : 'no more',
        'passenger info' : 'passenger info',
        'passenger' : 'passenger',
        'phone' : 'phone',
        'passengers' : 'passengers',
        'luggages' : 'luggages',
        'Click to change location' : 'Click to change location'
    },
    charter: {
        'fine drive': 'Fine drive',
        'fine drive mini': '',
        'Million yuan insurance': 'Million yuan insurance',
        'Million yuan insurance mini': '',
        'Quick invoicing': 'Quick invoicing',
        'Quick invoicing mini': '',
    },
    transfer : {
        'Flight delay waiting': 'Flight delay waiting',
        'Flight delay waiting mini': '',
        'Help guest to take luggage': 'Help guest to take luggage',
        'Help guest to take luggage mini': '',
        'Dress uniform': 'Dress uniform',
        'Dress uniform mini': '',
        'Provide mineral water': 'Provide mineral water',
        'Provide mineral water mini': '',
        'Power bank': 'Power bank Service',
        'Power bank mini': '',
        'Collection service': 'Collection service',
        'Collection service mini': '',
        'Provide WiFi': 'Provide WiFi',
        'Provide WiFi mini': '',
        'Customized services': 'Customized services',
        'Customized services mini': '',
    }
}