<template>
    <div id="transfer-container">
        <!--    页面主题-->
      <div class="banner-list">
        <div class="banner-item" v-for="(item, index) in  bannerList" >
          <img :src="item" :data-id="index">
        </div>
      </div>
      <div class="middle-container">
        <div class="middle-card">
          <div class="nav-box">
            <div class="nav-list">
              <div class="nav-item" :class="navIndex == 0 ? 'active' : ''" @click="navProjcet(0)">{{$t(itemList[0]['name'])}}</div>
              <div class="nav-item" :class="navIndex == 1 ? 'active' : ''" @click="navProjcet(1)">{{$t(itemList[1]['name'])}}</div>
            </div>
            <div class="nav-content">
              <div class="nav-content-box nav-content-box1" :class="navIndex == 0 ? '' : 'hidden'">
                <form class="nav-input-container" action="javascript:return true;" @submit.prevent>
                  <div class="nav-cricle-red"></div>
                  <input class="nav-input" type="search" :placeholder="$t('service.Please enter flight number')" v-model="flightNumber" @search="alertChooseTime">
                  <div class="search-btn" @click="alertChooseTime">
                        <img :src="icon.search" class="search-img">
                  </div>
                </form>
              </div>
              <div class="nav-content-box nav-content-box2" :class="navIndex == 1 ? '' : 'hidden'">
                <div class="nav-start-point" @click="mapAlertShow">
                  <div class="nav-cricle-green"></div>
                  {{ $t('service.Starting from') }} <span class="font-green">{{showAddress}}</span> {{ $t('service.go on') }}
                </div>
                <div class="nav-input-container" @click="alertChooseAirPort">
                  <div class="nav-cricle-red"></div>
                  <input class="nav-input nav-end-point" :placeholder="$t('service.Please enter your end')" v-model="airInputShow" readonly >
                </div>
              </div>
            </div>
          </div>
          <div class="info-show-container">
            <div class="info-show-top">{{ $t('service.professional and reassuring services') }}</div>
            <div class="info-show-list">
              <div class="info-show-item" v-for="(item, index) in list">
                <div class="info-show-item-box" :class="item.active ? 'active' : ''" @click='changeShowChoose(index)'>
                  <div class="info-show-icon">
                    <img class="icon-img" :src="item.Image">
                  </div>
                  <div class="show-title">{{$t(item.name1)}}</div>
                  <div class="show-content">{{$t(item.name2)}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <!--      弹窗-->
      <w-x-time-picker :pickerShow='pickerShow' @pickerShowClose="pickerShow=false" @confirm="onConfirm"></w-x-time-picker>

      <!-- 机场选择 --->
      <van-popup id="select-airport" v-model="airportShow" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
        <div class="city-name-container" @click="changeCityAlert">
          <div class="city-name">{{$t(city)}}<span>({{$t('service.choose city')}})</span></div>
        </div>
        <van-picker
            :title="$t('service.select airport')"
            :confirm-button-text =  "$t('common.confirm')"
            :cancel-button-text = "$t('common.cancel')"
            show-toolbar
            :columns="airportColumns"
            value-key="location"
            @cancel="airportShow = false"
            @confirm="onairportConfirm"
        ></van-picker>
      </van-popup>

      <!-- 航班时间选择 --->
      <van-popup v-model="timeShow" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
        <van-picker
            :title="$t('service.select date')"
            :confirm-button-text =  "$t('common.confirm')"
            :cancel-button-text = "$t('common.cancel')"
            show-toolbar
            :columns="timeColumns"
            value-key="date"
            @cancel="timeShow = false"
            @confirm="onTimeConfirm"
        ></van-picker>
      </van-popup>

      <!-- 航班选择 --->
      <van-popup v-model="airInfoshow" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
        <van-picker
            show-toolbar
            :confirm-button-text =  "$t('common.confirm')"
            :cancel-button-text = "$t('common.cancel')"
            :columns="airInfoColumns"
            value-key="content"
            @cancel="airInfoshow = false"
            @change="airInfoChange"
            @confirm="airInfoConfirm"
        ></van-picker>
      </van-popup>

      <!-- 城市选择-->
      <van-popup v-model="cityPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
        <van-picker
            :title="$t('common.Please choose city')"
            :confirm-button-text =  "$t('common.confirm')"
            :cancel-button-text = "$t('common.cancel')"
            show-toolbar
            :columns="cityList"
            value-key = "cityName"
            @cancel="cityPicker = false"
            @confirm="onLocalConfirm"
        ></van-picker>
      </van-popup>

      <ChooseHotelMap :class="mapShow ? '': 'hidden'" :show="mapShow"  :outHotelId='hotelid' @confirm="onConfirm2" @closeAlert="closeAlert"></ChooseHotelMap>

      <van-overlay :show="loadShow"  class="overlay-div">
        <van-loading type="spinner"  class="load"/>
      </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Overlay, Loading, Popup, Picker, Toast} from 'vant';
    import WXTimePicker from '@/components/WXTimePicker.vue';
    import ChooseHotelMap from "@/components/ChooseHotelMap.vue";
    vue.use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast);
    export default {
        name: 'transfer',
        components:{
          ChooseHotelMap,
          WXTimePicker
        },
        data() {
            return {
              loadShow :false,
              mapShow : false,
              hotelid : 0,
              itemList: [
                {'name' : 'service.pick-up', 'item_id' : '26'},
                {'name' : 'service.drop-off', 'item_id' : '27'}
              ],
              itemId : 26,
              list: [
                {
                  Image: '/images/index/icon/01_11de.png',
                  name1: 'transfer.Customized services',
                  name2: 'transfer.Customized services mini',
                  active:  true,
                },
                {
                  Image: '/images/index/icon/01_11wetrf.png',
                  name1: 'transfer.Flight delay waiting',
                  name2: 'transfer.Flight delay waiting mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11werw.png',
                  name1: 'transfer.Help guest to take luggage',
                  name2: 'transfer.Help guest to take luggage mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11terhf.png',
                  name1: 'transfer.Dress uniform',
                  name2: 'transfer.Dress uniform mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11rthwd.png',
                  name1: 'transfer.Provide mineral water',
                  name2: 'transfer.Provide mineral water mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11erggf.png',
                  name1: 'transfer.Power bank',
                  name2: 'transfer.Power bank mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11wghgyjh.png',
                  name1: 'transfer.Collection service',
                  name2: 'transfer.Collection service mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11bhuikjh.png',
                  name1: 'transfer.Provide WiFi',
                  name2: 'transfer.Provide WiFi mini',
                  active: true
                },
                {
                  Image: '/images/index/icon/01_11tyhn.png',
                  name1: 'transfer.Customized services',
                  name2: 'transfer.Customized services mini',
                  active: true
                }
              ],
              pickerShow: false,
              icon: {
                search: '/images/icon/icon-search-w.png'
              },
              bannerList: [
                  '/images/service/transfer-750@3x.jpg'
              ],
              navIndex: 0,
              showAddress : '-',
              hotelPoint : '',
              airportShow: false,
              airportColumns: [],
              airInputShow: '',
              timeColumns: [],
              timeShow: false,
              airInfoshow: false,
              airInfoColumns: [],
              flightNumber: '',
              end: '',
              endPoint: '',
              start: '',
              startPoint: '',
              cityId : this.$store.getters['local/getCityId'],
              city: 'service.shanghai',
              cityPicker : false,
              cityList : []
            }
        },
        created: function () {
          this.getHotelAddress();

          //暂未开放其他城市
          // this.city = this.$store.getters['order/getCity']; //暂未获取
          this.hotelid = Number(this.$store.getters['user/getHotelId']);

          var itemId = this.$store.getters['order/getItemId'];
          if(itemId == '27'){
            this.navIndex = 1;
            this.itemId = 27;
          }
        },
      mounted() {
        if (window.history && window.history.pushState) {
          history.pushState(null, null, document.URL)
          window.addEventListener('popstate', this.back, false)
        }
      },
      destroyed() {
           window.removeEventListener('popstate', this.back, false)
            return false;
         },
      methods: {
          back(){
            if(this.mapShow){
              this.mapShow = false;
            }else{
              this.$router.back();
            }
          },
          getHotelAddress(){
            this.loadShow = true;
            this.$post('/getHotelAddress', {hotelid: this.$store.getters['user/getHotelId']}
            ).then((res)=>{
              if(res.code == 1000){
                var lang = this.$store.getters['local/getLanguages'];

                if(lang == 'en'){
                  this.showAddress = res.data.name_en;
                }else{
                  this.showAddress = res.data.address;
                }
                // this.city = res.data.city //暂未获取
                this.$store.commit('order/setHotelAddress', this.showAddress)
                this.$store.commit('order/setCity', this.city)
                this.hotelPoint = res.data.lat +','+res.data.lon;
              }else{
                Toast(res.msg);
              }
            }).finally(() => {
               this.loadShow = false;
            })
          },
          /**
           * 载入城市
           */
          loadCityList(){
            return this.$post('/getCityCode', {
              'lang' : this.$store.getters['local/getLanguages']
            });
          },
          /**
           * 选择时间
           * @param e
           */
          onConfirm: function(e){
            this.$store.commit('order/setTime', e);
            this.pickerShow= false;

            //跳转到选车
            this.navToCarSeries();
          },
          alertChooseAirPort(){
            this.loadShow = true;
            var lang = this.$store.getters['local/getLanguages'];
            this.$post('/getCityLocation', {
              company_id: this.cityId
            }).then((res)=>{
              if(res.code == 1000){
                if(res.data.length > 0 && lang == 'en'){
                  for (var i=0; i < res.data.length; i++){
                    res.data[i]['location'] = res.data[i]['location_en'];
                  }
                }
                this.airportColumns = res.data
                this.airportShow =true;
              }else{
                Toast(res.msg);
              }
            }).catch((e)=>{
                Toast(e.msg);
            }).finally(()=>{
              this.loadShow =false;
            })
          },

          /**
           *  确认机场(送机)
           */
          onairportConfirm(e){
            this.end = e.location;
            this.endPoint = e.lat +','+e.lon;
            this.start = this.showAddress;
            this.startPoint = this.hotelPoint;
            this.airportShow = false;
            this.pickerShow = true;
          },
          alertChooseTime(){
            if(this.flightNumber == ''){
              Toast('请填写航班号');
              return false;
            }
            var time = new Date().getTime();
            var dateList = [];

            for (var i = -1; i < 15 ; i++){
              var setTime =time + (i * 3600 * 24 * 1000);
              var date = new Date(setTime);
              dateList.push({
                time: setTime,
                date : (date.getMonth() + 1) + '月' + date.getDate() + '日 '
              });
            }

            this.timeColumns = dateList;

            this.timeShow = true;
          },
          /**
           * 确认时间（触发获取航班号信息)
           */
          onTimeConfirm(e){
            this.loadShow = true;
            var newDate = new Date(e.time);
            var month = (Array(2).join(0) + (newDate.getMonth()+ 1)).slice(-2);
            var day = (Array(2).join(0) + newDate.getDate()).slice(-2);
            var date = newDate.getFullYear() + month + day;
            this.$store.commit('order/setTime', e.time);
            this.$get('/treeFilght/api/v1/flight/'+ this.flightNumber + '/' + date).then((res)=>{
              if(res.code === 200){
                var lang = this.$store.getters['local/getLanguages'];
                if(res.data[0]['END_DATE'] == '') {
                  Toast('航班信息异常');
                  return false
                }
                if(res.data.length > 0 && res.data.length < 5){
                  for (var i = 0; i < res.data.length; i++){
                    if(lang == 'en'){
                      res.data[i]['content'] = res.data[i]['START_AIRPORT_EN'] + res.data[i]['START_TERMINAL_EN'] + '-' + res.data[i]['END_AIRPORT_EN'] + res.data[i]['END_TERMINAL_EN']+ '(' + res.data[i]['END_TIME'] +')'
                      res.data[i]['start'] = res.data[i]['END_AIRPORT_EN'] + res.data[i]['END_TERMINAL_EN']
                    }else{
                      res.data[i]['content'] = res.data[i]['START_AIRPORT_CH'] + res.data[i]['START_TERMINAL_EN'] + '-' + res.data[i]['END_AIRPORT_CH'] + res.data[i]['END_TERMINAL_EN']+ '(落地:' + res.data[i]['END_TIME'] +')'
                      res.data[i]['start'] = res.data[i]['END_AIRPORT_CH'] + res.data[i]['END_TERMINAL_EN']
                    }
                    res.data[i]['datetime'] = res.data[i]['END_DATE']+ ' ' + res.data[i]['END_TIME']
                  }
                }else{
                  Toast('航班信息异常');
                  return false
                }
                this.airInfoshow = true;
                this.timeShow =false;

                 this.airInfoColumns = res.data;
              }else{
                Toast(res.msg);
              }
            }).catch((e)=>{
              Toast(e.msg)
            }).finally(()=>{
              this.loadShow = false;
            });
          },
          airInfoChange(e){
            console.log(e);
          },
          airInfoConfirm(e){
            this.start = e.start;
            this.end = this.showAddress;
            this.endPoint = this.hotelPoint;

            var FlightArrtimePlantime = this.getAirtTime(e.datetime);
            //存入时间
            this.$store.commit('order/setTime', FlightArrtimePlantime);

            this.navToCarSeries();
          },
          getAirtTime: (date) => {
            let year = date.substr(0, 4);
            let month = date.substr(4, 2) - 1; // JS中月份是从0开始的
            let day = date.substr(6, 2);
            let time = date.substr(9, date.length - 1)
            let outDate = year + '-' + month +'-' +day + ' ' + time
            return new Date(outDate).getTime();
          },
          navProjcet: function(index){
            if(this.navIndex == index) return false;
            this.navIndex = index;
            this.itemId = this.itemList[index]['item_id'];

            var list = this.list;
            for (var i = 0 ; i < list.length; i++){
              list[i]['active'] = true;
            }
            this.list = list;

            return true;
          },
          /**
           * 前往车系
           */
          navToCarSeries(){
            // this.$store.commit('order/setTime');
            this.$store.commit('order/setFlightNumber', this.flightNumber);
            this.$store.commit('order/setEnd', this.end);
            this.$store.commit('order/setEndPoint', this.endPoint);
            this.$store.commit('order/setStart', this.start);
            this.$store.commit('order/setStartPoint', this.startPoint);
            this.$store.commit('order/setHotelId', this.hotelid)
            this.$router.push({
              path: '/order_car?item_id='+ this.itemId
            })
          },
          changeShowChoose(index){
            return false;
            this.list[index]['active'] = !this.list[index]['active'];
          },
          mapAlertShow(){
            this.mapShow = true;
          },
          onConfirm2(e){
            this.hotelid = e.hotelid;
            this.showAddress = e.abbreviation;
            this.mapShow = false;
          },
          closeAlert(){
            this.mapShow = false;
          },
          changeCityAlert(){
            // Toast(this.$i18n.t('common.city Temporarily Closed'));
            var that = this;
            this.loadCityList().then((res)=>{
              if(res.bizCode == 1000){
                this.cityList = res.data
                this.cityPicker =true;
              }else{
                Toast(res.msg);
              }
            }).catch((e)=>{
              Toast(e.msg)
            })

          },
          onLocalConfirm(e){
            this.city = e.cityName;
            this.cityCode = e.cityCode;
            this.cityId = e.company_id;

            this.$store.commit('local/setCityCode', this.cityCode);
            this.$store.commit('local/setCityId', this.cityId);

            this.alertChooseAirPort();
            this.cityPicker = false;
          }
        }
    }
</script>

<style>
  #transfer-container{

  }
  #transfer-container .banner-list{
    width: 100%;
    position: relative;
  }
  #transfer-container .banner-list .banner-item{
    width: 100%;
  }
  #transfer-container .banner-list .banner-item img{
    width: 100%;
  }
  #transfer-container .middle-container{
    width: 100%;
    padding: 0 30px;
    margin-top: -50px;
    position: relative;
    z-index: 1;
  }
  #transfer-container .middle-card{
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    box-shadow: 0 2px 10px #000;
  }
  #transfer-container .middle-card .nav-box{
    box-shadow: 0 0 10px #000;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  #transfer-container .middle-card .nav-list{
    display: flex;
  }
  #transfer-container .middle-card .nav-list .nav-item{
    width: 50%;
    background: #E2E0E0;
    font-size: 14px;
    line-height: 46px;
  }
  #transfer-container .middle-card .nav-list .nav-item.active{
    background: #fff;
  }

  #transfer-container .middle-card .nav-content-box{
    padding: 16px 16px;
    border-top: 1px solid #E2E0E0;
  }
  #transfer-container .middle-card .nav-content-box.hidden{
    display: none;
  }
  #transfer-container .middle-card .nav-content-box .nav-input-container{
    background: #E2E0E0;
    border-radius: 6px;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    position: relative;
  }
  #transfer-container .middle-card .nav-content-box .nav-cricle-red{
    position: absolute;
    width: 6px;
    height: 6px;
    left: 7px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: #D75F5F;
  }
  #transfer-container .middle-card .nav-content-box .nav-cricle-green{
    position: absolute;
    width: 6px;
    height: 6px;
    left: 7px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    background: #9ED75F;
  }
  #transfer-container .middle-card .nav-content-box .nav-input-container input{
    border: none;
    background: transparent;white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    width: 100%;
    padding-left: 20px;
  }
  #transfer-container .middle-card .nav-content-box .nav-start-point{
    border-bottom: 1px solid #BBBBBB;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    text-align: left;
    padding-left: 20px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  }
  #transfer-container .middle-card .nav-content-box .nav-start-point .font-green{
    color: #8DBA15;
    font-size: 12px;
    display: inline-block;
    width: 60%;
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏超出容器的内容 */
    text-overflow: ellipsis; /* 超出部分显示省略号 */
  }
  #transfer-container .middle-card .nav-content-box2 .nav-input-container{
    margin-top: 20px;
  }

  #transfer-container .middle-card .info-show-container{
    padding: 20px 0;
  }

  #transfer-container .middle-card .info-show-top{
    text-align: center;
    line-height: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  #transfer-container .middle-card .info-show-list{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 30px;
    width: 100%;
  }
  #transfer-container .middle-card .info-show-list .info-show-item{
    width: 33.33%;
    font-size: 12px;
    padding: 8px 4px;
  }

  #transfer-container .middle-card .info-show-list .info-show-item .info-show-item-box{
    background: #ede9e9;
    padding: 8px 0;
    border-radius: 8px;
  }
  #transfer-container .middle-card .info-show-list .info-show-item .info-show-item-box.active{
    background: #fff;
  }
  #transfer-container .middle-card .info-show-list .info-show-icon{
    height: 28px;
    width: 28px;
    position: relative;
    margin: auto;
  }
  #transfer-container .middle-card .info-show-list .info-show-icon img{
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 0;
    margin: auto;
  }
  #transfer-container .middle-card .info-show-list .info-show-item .show-title{
    font-weight: bold;
    padding: 6px 0;
  }

  #transfer-container .hidden{
    display: none;
  }

  #transfer-container .search-btn{
    position: absolute;
    background: #b98a73;
    top: 0;
    bottom: 0;
    right: 0;
    height: 46px;
    width: 50px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  #transfer-container .search-btn .search-img{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  #select-airport .city-name-container{
    width: 50%;
  }
  #select-airport .city-name{
    font-size: 14px;
    text-align: left;
    padding: 0 16px;
    padding-top: 10px;
    line-height: 20px;
  }

  #select-airport .city-name span{
    color: #bcbcbc;
  }
</style>
