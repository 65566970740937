import Vue from 'vue'
import App from './App.vue'
import './common/font/pf-m.css'
import './common/font/pf-b.css'
import wechat from './config/wechat'

import router from './router'
import store from './store/index';
import axios from 'axios'
import md5 from 'js-md5';
import {post, fetch, uploadFile, postOldUrl} from "./config/axios";
import {timeFormat, isset, strToTime, loginType, loginWXType} from "@/config/utils";
import VueI18n from 'vue-i18n';

Vue.prototype.$axios = axios;
Vue.prototype.$wechat = wechat;

Vue.prototype.$get = fetch;
Vue.prototype.$post = post;
Vue.prototype.$postOldUrl = postOldUrl;
Vue.prototype.$uploadFile = uploadFile;
Vue.prototype.$md5 = md5;
Vue.prototype.$timeFormat = timeFormat;
Vue.prototype.$isset = isset;
Vue.prototype.$strToTime = strToTime;
Vue.prototype.$loginWXType = loginWXType;
Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = new VueI18n({
    locale: store.getters['local/getLanguages'],//表示默认的语言 修改全局locale值可以导入message中不同的包
    messages: {
        'zh': require('./lang/zh'), // 中文语言包
        'en': require('./lang/en'), // 英文语言包
    }
})

//获取用户id和token

router.beforeEach((to, form, next) => {
    if(isset(to.query.qrCode)){
        //二维认证码
        store.commit('local/setQrCode', to.query.qrCode);
    }

    if(to.path=='/service_en' || to.path=='/service_zh'){
        next();
        return;
    }

    //没userId就前往登录功能
    let userId = store.getters['user/getUserId'];
    if(userId == 0 || userId == null || userId == undefined || userId == 'undefined' || userId == ''){
        //没有登录userid 前往登录
        if(to.path != '/login'){
            next('/login');
            return;
        }
    }

    next();
    return;
});


//后置钩子 用户配置wx配置
router.afterEach((to, from) => {
    if(to.name == 'order_detail'){
        //如果是个人页面才会获取配置
        let url = wechat.baseUrl + to.fullPath;
        Vue.prototype.$postOldUrl('/user/getWXConfig', {
            pathUrl  : url,
        }).then((res) => {
            if(res.code == 1000){
                let data = res.data;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: wechat.appId, // 必填，公众号的唯一标识
                    timestamp: data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonce_str, // 必填，生成签名的随机串
                    signature: data.signature,// 必填，签名
                    jsApiList: ['showMenuItems','hideAllNonBaseMenuItem', 'getLocation','updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData', 'onMenuShareTimeline', 'chooseWXPay', 'closeWindow'], // 必填，需要使用的JS接口列表
                    openTagList: ['wx-open-launch-weapp']
                });
                wx.error(function(res){
                    console.log(res);
                    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                });
            }else{
                alert(res.msg);
            }
        })
    }
});
new Vue({
    i18n,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
