<template>
  <div id="bottom-nav-list">
    <div class="nav-item">
      <img :src="icon.home" @click="navToHome">
    </div>
    <div class="nav-item">
      <img :src="icon.list" @click="navToList">
    </div>
  </div>
</template>

<script>

export default {
  name: 'BottomNav',
  props: {
    msg: String
  },
  data() {
    return {
      icon: {
        home: '/images/icon/icon-bottom-home@3x.png',
        list: '/images/icon/icon-bottom-list2.png',
      }
    }

  },
  methods:{
    navToHome(){
      this.$router.replace('/index')
    },
    navToList(){
      this.$router.replace('/order_list');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bottom-nav-list{
  position: fixed;
  bottom: 0px;
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: content-box;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 6px #000;
  overflow: hidden;
}
#bottom-nav-list .nav-item{
  width: 50%;
  border-bottom: 1px solid #BBBBBB;
  position: relative;
}
#bottom-nav-list .nav-item:nth-child(2){
  border-left: 1px solid #BBBBBB;
}
#bottom-nav-list .nav-item img{
  width: 16px;
  height: 16px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}
</style>
