<template>
  <div id="charter-container">
    <!--    页面主题-->
    <div class="banner-list">
      <div class="banner-item" v-for="(item, index) in  bannerList" >
        <img :src="item" :data-id="index">
      </div>
    </div>
    <div class="middle-container">
      <div class="middle-card">
        <div class="nav-box">
          <div class="nav-list">
            <div class="nav-item" :class="navIndex == 1 ? 'active' : ''" @click="navProjcet(1)">{{$t('service.luxury car')}}</div>
            <div class="nav-item" :class="navIndex == 2 ? 'active' : ''" @click="navProjcet(2)">{{$t('service.business Van')}}</div>
          </div>
          <div class="nav-content">
            <div class="nav-content-box nav-content-box1" :class="navIndex == 1 ? '' : 'hidden'">
              <div class="item-list">
                <div class="item-item" v-for="(item, index) in itemList">
                  <img :src="item.image" :data-id="index" @click="alertTimePicker(item.item_id)">
                </div>
              </div>
            </div>
            <div class="nav-content-box nav-content-box2" :class="navIndex == 2 ? '' : 'hidden'">
              <div class="item-list">
                <div class="item-item" v-for="(item, index) in itemList">
                  <img :src="item.image" :data-id="index" @click="alertTimePicker(item.item_id)">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-show-container">
          <div class="info-show-top">{{ $t('service.professional and reassuring services') }}</div>
          <div class="info-show-list">
            <div class="info-show-item" v-for="(item, index) in list">
              <div class="info-show-icon">
                <img class="icon-img" :src="item.Image">
              </div>
              <div class="show-title">{{$t(item.name1)}}</div>
              <div class="show-content">{{$t(item.name2)}}</div>
            </div>
          </div>
          <div class="info-show-bottom">{{ $t('service.our 24-hour service line') }} <span class="font-gold">400-6737-888</span></div>
        </div>
      </div>
    </div>

    <!--      弹窗-->
    <w-x-time-picker :pickerShow='pickerShow' @pickerShowClose="pickerShow=false" @confirm="onConfirm"></w-x-time-picker>

  </div>
</template>

<script>
import vue from 'vue';
import {Button, Overlay, Loading, Popup, Picker, Toast, DatetimePicker,Checkbox} from 'vant';
import WXTimePicker from "@/components/WXTimePicker.vue";
import BottomNav from "@/components/BottomNav.vue";
vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(DatetimePicker).use(Checkbox);
export default {
  name: 'charter',
  components: {BottomNav, WXTimePicker},
  data() {
    return {
      hotelid: 0,
      pickerShow: false,
      list: [
        {
          Image: '/images/index/icon/01_19Group 333.png',
          name1: 'charter.fine drive',
          name2: 'charter.fine drive mini'
        },
        {
          Image: '/images/index/icon/01_19regf.png',
          name1: 'charter.Million yuan insurance',
          name2: 'charter.Million yuan insurance mini'
        },
        {
          Image: '/images/index/icon/01_19ghjkh.png',
          name1: 'charter.Quick invoicing',
          name2: 'charter.Quick invoicing mini'
        }
      ],
      icon: {
      },
      bannerList: [
        '/images/service/charater-banner@3x.png'
      ],
      itemList: [
        {
          image: '/images/service/icon-charter-2h.png',
          image_zh: '/images/service/icon-charter-2h_zh-gray.png',
          image_en: '/images/service/icon-charter-2h_en-gray.png',
          name1: '2 hours',
          // 'item_id' : 41
          'item_id' : 0
        },
        {
          image: '/images/service/icon-charter-4h.png',
          image_zh: '/images/service/icon-charter-4h_zh.png',
          image_en: '/images/service/icon-charter-4h_en.png',
          name1: '4 hours',
          'item_id' : 40
        },
        {
          image: '/images/service/icon-charter-8h.png',
          image_zh :'/images/service/icon-charter-8h_zh.png',
          image_en: '/images/service/icon-charter-8h_en.png',
          name1: '8 hours',
          'item_id' : 39
        },
      ],
      navIndex: 1,
      showAddress: '',
      hotelPoint : '',
      city : '',
      itemId : 0
    }
  },
  created: function () {
    this.hotelid= this.$store.getters['user/getHotelId']
    this.getHotelAddress();
    this.showItemImg();
  },
  methods: {
    getHotelAddress(){
      this.loadShow = true;
      this.$post('/getHotelAddress', {hotelid: this.hotelid}
      ).then((res)=>{
        if(res.code == 1000){
          var lang = this.$store.getters['local/getLanguages'];

          if(lang == 'en'){
            this.showAddress = res.data.name_en;
          }else{
            this.showAddress = res.data.address;
          }
          this.city = res.data.city;
          this.$store.commit('order/setHotelAddress', this.showAddress);
          this.$store.commit('order/setCity', this.city);
          this.hotelPoint = res.data.lat +','+res.data.lon;
        }else{
          Toast(res.msg);
        }
      }).finally(() => {
        this.loadShow = false;
      })
    },
    /**
     * 弹窗
     * @param e
     */
    alertTimePicker(item_id){
      if(item_id == 0){
        Toast('暂未开放该服务');
        return false;
      }
      this.itemId = item_id;
      this.pickerShow = true;
    },
    /**
     * 时间确定
     * @param e
     */
    onConfirm: function(e){
      this.$store.commit('order/setTime', e);
      this.pickerShow= false;
      this.navToCarSeries();
    },
    navProjcet: function (index) {
      if (this.navIndex == index) return false;

      if(index == 2){
        Toast('暂未可选车型');
        return false;
      }

      this.navIndex = index;
      return true;
    },
    navToCarSeries(){
      if(this.itemId == 0){
        Toast('该服务暂不可选');
        return false;
      }

      this.$store.commit('order/setStart', this.showAddress);
      this.$store.commit('order/setStartPoint', this.hotelPoint);
      this.$store.commit('order/setEnd', "");
      this.$store.commit('order/setEndPoint', "");
      this.$store.commit('order/setHotelId', this.hotelid)
      this.$router.push({
        path: '/order_car?item_id='+ this.itemId + '&type='+ this.navIndex
      })
    },
    showItemImg(){
      var languages = this.$store.getters['local/getLanguages'];
      if(this.itemList.length > 0){
        for (var i = 0; i < this.itemList.length; i++){
          switch (languages){
            case 'zh':
              this.itemList[i]['image'] = this.itemList[i]['image_zh'];
              continue;
            case 'en':
              this.itemList[i]['image'] = this.itemList[i]['image_en'];
              continue;
          }
        }
      }
    }
  }
}
</script>

<style>
#charter-container {

}

#charter-container .banner-list {
  width: 100%;
  position: relative;
}

#charter-container .banner-list .banner-item {
  width: 100%;
}

#charter-container .banner-list .banner-item img {
  width: 100%;
}

#charter-container .middle-container {
  width: 100%;
  padding: 0 30px;
  margin-top: -25px;
  position: relative;
  z-index: 1;
}

#charter-container .middle-card {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  box-shadow: 0 2px 10px #000;
}

#charter-container .middle-card .nav-box {
  box-shadow: 0 0 10px #000;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

#charter-container .middle-card .nav-list {
  display: flex;
}

#charter-container .middle-card .nav-list .nav-item {
  width: 50%;
  background: #E2E0E0;
  font-size: 14px;
  line-height: 46px;
}

#charter-container .middle-card .nav-list .nav-item.active {
  background: #fff;
}

#charter-container .middle-card .nav-content-box {
  padding: 16px 16px;
  border-top: 1px solid #E2E0E0;
}

#charter-container .middle-card .nav-content-box.hidden {
  display: none;
}

#charter-container .middle-card .item-list{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#charter-container .middle-card .item-list .item-item{
  width: 33.33%;
}
#charter-container .middle-card .item-list .item-item:nth-child(1),#charter-container .middle-card .item-list .item-item:nth-child(3){
  //transform: translateY(50%);
  margin-top: 45px;
}
#charter-container .middle-card .item-list .item-item img{
  width: 100%;
}

#charter-container .middle-card .info-show-container{
  width: 100%;
  padding: 20px 0;
}
#charter-container .middle-card .info-show-top{
  text-align: center;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
#charter-container .middle-card .info-show-bottom{
  text-align: center;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 14px;
}
#charter-container .middle-card .info-show-bottom .font-gold{
  color: #B98A73;
}

#charter-container .middle-card .info-show-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding:  0 30px;
  width: 100%;
}

#charter-container .middle-card .info-show-list .info-show-item {
  width: 33.33%;
  font-size: 12px;
  padding: 16px 0;
}

#charter-container .middle-card .info-show-list .info-show-icon {
  height: 28px;
  width: 28px;
  position: relative;
  margin: auto;
}

#charter-container .middle-card .info-show-list .info-show-icon img {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 0;
  margin: auto;
}

#charter-container .middle-card .info-show-list .info-show-item .show-title {
  font-weight: bold;
  padding: 6px 0;
}
</style>
