/**
 * 选择地址信息
*/
const local = {
    namespaced: true,
    state : {
        provinceId : 0,
        cityId : 3,
        cityCode : '310000',
        type : 0, //1直购车 2特价车 3个人中心
        local : '',
        url : '',
        languages: '',
        pList : '',
        qrCode : '',
    },
    mutations: {
        setProvinceId(state, provinceId) {
            state.provinceId = provinceId;
            sessionStorage.provinceId = provinceId //同步存储provinceId至sessionStorage
        },
        setCityId(state,cityId){
            state.cityId = cityId;
            sessionStorage.cityId = cityId //同步存储cityId至sessionStorage
        },
        setCityCode(state,cityCode){
            state.cityId = cityCode;
            sessionStorage.cityCode = cityCode //同步存储cityId至sessionStorage
        },
        setLocal(state,local){
            state.local = local;
            sessionStorage.local = local //同步存储local至sessionStorage
        },
        setType(state,type){
            state.type = type;
            localStorage.localtype = type //同步存储local至sessionStorage
        },
        setUrl(state,url){
            state.url = url;
            localStorage.localtypeurl = url //同步存储local至sessionStorage
        },
        setLanguages(state,languages){
            state.languages = languages;
            localStorage.languages = languages //同步存储local至sessionStorage
        },
        setPList(state,pList){
            state.pList = pList;
            localStorage.pList = pList //同步存储local至sessionStorage
        },
        setQrCode(state,qrCode){
            state.qrCode = qrCode;
            sessionStorage.qrCode = qrCode //同步存储local至sessionStorage
        },
        delQrCode(state){
            sessionStorage.removeItem('qrCode');
            state.qrCode = '';
        }
    },
    getters : {
        getProvinceId(state) {
            if (!state.provinceId) {
                state.provinceId = sessionStorage.getItem('provinceId');
            }
            return state.provinceId
        },
        getCityId(state){
            if (!state.cityId) {
                state.cityId = sessionStorage.getItem('cityId');
            }
            return state.cityId
        },
        getCityCode(state){
            if (!state.cityCode) {
                state.cityCode = sessionStorage.getItem('cityCode');
            }
            return state.cityCode
        },
        getLocal(state){
            if (!state.local) {
                state.local = sessionStorage.getItem('local');
            }
            return state.local
        },
        getType(state){
            if (!state.type) {
                state.type = localStorage.getItem('localtype');
            }
            return state.type
        },
        getUrl(state){
            if (!state.url) {
                state.url = localStorage.getItem('localtypeurl');
            }
            return state.url
        },
        getLanguages(state){
            if (!state.languages) {
                state.languages = localStorage.getItem('languages');
            }
            if(!state.languages || state.languages == null){
                state.languages = 'zh';
            }
            return state.languages
        },
        getPList(state){
            if (!state.pList) {
                state.pList = localStorage.getItem('pList');
            }
            return state.pList
        },
        getQrCode(state){
            if (!state.qrCode) {
                state.qrCode = sessionStorage.getItem('qrCode');
            }
            return state.qrCode
        },
    },
    actions: {
    }
};

export default  local;