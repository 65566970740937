<template>
    <div v-show="visible" class="map" id="the_map">
            <img class="x-icon" src="/images/icon/act-icon-x.png" @click="closeAlert"/>
            <div class="title">地址选择</div>
            <div class="input-container">
                <img class="find-icon" src="/images/icon/icon-map-search.png"/>
                <input class="map-change" id="suggestId" v-model="inputContent" @click="selectValue($event)"/>
                <div class="map-sub-btn"  @click="selectLocation">确定</div>
                <div id="searchResultPanel" style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
            </div>
            <div id="map-core"></div>
        </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'AddressSearch',
        data () {
            return {
                visible:true,
                location: {
                    lng: '',
                    lat: '',
                    key: '',
                    province: ''
                },
                map: {},
                ac: {},
                inputContent: '',
            }
        },
        mounted () {
            //直接用IP定位
            var that = this;
            function myFun(result){

                that.setMap(result.center.lng,result.center.lat);
            }
            var myCity = new BMap.LocalCity();

            myCity.get(myFun);
            console.log('mounted');
            // this.setSearch()
        },
        created () {
            this.$parent.$on('changeMapInfo', (data) => { // 监听名为'data'的事件
                // console.log('回传数据'); // 输出传递的数据
                var that = this;
                that.location = data;

                that.map.clearOverlays();    //清除地图上所有覆盖物
                that.map.centerAndZoom(data, 17);
                that.map.addOverlay(new BMap.Marker(data));    //添加标注
                that.inputContent = data.key;
            });
        },
        methods: {
            // 初始化地图
            setMap (lng, lat) {
                var that = this;
                function G(id) {
                    return document.getElementById(id);
                }
                this.map = new BMap.Map('map-core');
                this.map.centerAndZoom(new BMap.Point(lng, lat), 10);
                var ac = new BMap.Autocomplete({
                    "input" : "suggestId",
                    "location" : this.map,
                    'onSearchComplete': that.onSearchComplete
                })
                ac.addEventListener("onhighlight", function(e) {  //鼠标放在下拉列表上的事件
                    var str = "";
                    var _value = e.fromitem.value;
                    var value = "";
                    if (e.fromitem.index > -1) {
                        value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                    }
                    str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

                    value = "";
                    if (e.toitem.index > -1) {
                        _value = e.toitem.value;
                        value = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                    }
                    str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                    G("searchResultPanel").innerHTML = str;
                });

                var myValue;
                ac.addEventListener("onconfirm", function(e) {    //鼠标点击下拉列表后的事件
                    var _value = e.item.value;

                    that.location.key = _value.keywords;
                    myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                    G("searchResultPanel").innerHTML ="onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;

                    setPlace();
                });
                function setPlace(){
                    that.map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun(){
                        var data = local.getResults();
                        var pp = data.getPoi(0).point;    //获取第一个智能搜索的结果
                        that.map.centerAndZoom(pp, 17);
                        that.map.addOverlay(new BMap.Marker(pp));    //添加标注
                        var key = data.keyword;
                        var province = data.province;
                        that.location = {
                            lng: pp.lng,
                            lat: pp.lat,
                            key: key,
                            province: province
                        }
                    }
                    var local = new BMap.LocalSearch(that.map, { //智能搜索
                        onSearchComplete: myFun
                    });
                    local.search(myValue);
                }
            },
            onSearchComplete: function(e){
                var that = this;
                var addressList =e.Wr;
                var _value = addressList[0];
                that.location.key = _value.keywords;
                let myValue = _value.province +  _value.city +  _value.district +  _value.street +  _value.business;
                G("searchResultPanel").innerHTML ="onconfirm<br />index = 0<br />myValue = " + myValue;
                setPlace();

                function G(id) {
                    return document.getElementById(id);
                }
                function setPlace(){
                    that.map.clearOverlays();    //清除地图上所有覆盖物
                    function myFun(){
                        var data = local.getResults();
                        if(data.getPoi(0)){
                            var pp = data.getPoi(0).point;    //获取第一个智能搜索的结果
                            that.map.centerAndZoom(pp, 17);
                            that.map.addOverlay(new BMap.Marker(pp));    //添加标注
                            var key = data.keyword;
                            var province = data.province;

                            that.location = {
                                lng: pp.lng,
                                lat: pp.lat,
                                key: key,
                                province: province
                            }
                        }
                    }
                    var local = new BMap.LocalSearch(that.map, { //智能搜索
                        onSearchComplete: myFun
                    });
                    local.search(myValue);
                }
            },
            // 向父组件传递经纬度
            selectLocation () {
                this.$emit('selectLocation', this.location)
            },
            closeAlert(){
                this.$emit('closeAlert', this.location)
            },
            selectValue: function(e){
                // e.currentTarget.select();
            }
        },
        watch: {
            'location': {
                handler () {
                    // this.$emit('getLocation',this.location);
                },
                deep: true
            },
            visible () {
                console.log('ddd')
            }
        }
    }
</script>

<style scoped>
    .map {
        width: 100%;
        height: 400px;
        font-size: 14px;
        position: relative;
        background: linear-gradient( to right, #fafafc 0%, #e3e4e6 100%);
        border-radius: 15px;
        overflow: hidden;
    }
    #the_map.map .x-icon{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 18px;
        height: 18px;
    }
    .map .title{
        color: #000;
        font-size: 18px;
        line-height: 30px;
        padding-top: 14px;
    }
    .map #map-core {
        width: 100%;
        height: 300px;
        margin: 0;
        margin-top: 10px;
    }
    .map .input-container{
        position: relative;
        padding-left: 18px;
        padding-right: 60px;
    }
    .map #suggestId{
        width: 100%;
        border: 1px solid #434343;
        border-radius: 15px;
        padding: 4px 0;
        padding-left: 30px;
        padding-right: 12px;
        background: transparent;
    }
    #the_map.map .input-container .find-icon{
        position: absolute;
        left: 30px;
        height: 12px;
        width: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    .map .input-container .map-sub-btn{
        display: block;
        position: absolute;
        right: 23px;
        top: 0;
        bottom: 0;
        margin: auto;
        line-height: 26px;
        /*color: #535353;*/
        color: #0081a4;
    }
    .map .search {
        display: flex;
        margin-top: 10px;
        height: 40px;
        align-items: center;
        justify-content: center;
    }
    .map .search #r-result {
        display: flex;
        align-items: center;
        height: 40px;
        background-color: rgb(255,255,255);
    }
    .map .search .lng-lat {
        display: flex;
    }
</style>