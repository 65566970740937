<template>
    <div id="order_detail-container">
        <!--    页面主题-->
      <div class="banner-list">
        <div class="banner-item" v-for="(item,index) in  bannerList">
          <img class="banner-img" :src="item">
        </div>
      </div>
      <div class="detail-info-container">
        <div class="info-container">
          <div class="info-box info-box1">
            <div class="info-appointment">{{detail.appointment}}</div>
            <div class="info-status_text" :class="detail.status == 0 ? 'hidden' : ''">{{detail.status_text}}</div>
          </div>
          <div class="info-box info-box2">
            <div class="point-box">
              <div class="left-line"></div>
              <div class="info-start_point" @click="changePoint(1)">({{ $t('order.start') }}) {{detail.start_point}} <div v-if="miniShow && detail.status == 1" class="point-info-mini">({{$t('order.Click to change location')}})</div></div>
              <div class="info-middle"></div>
              <div class="info-start_point" @click="changePoint(2)">({{ $t('order.end') }}) {{detail.end_point}}<div v-if="miniShow && detail.status == 1" class="point-info-mini">({{ $t('order.Click to change location') }})</div></div>
            </div>
          </div>
          <div class="info-box info-box3">
            <div class="info-start_point">{{ $t('order.service type') }} : {{detail.type_text}}</div>
            <div class="info-start_point">{{ $t('order.car series') }}  : {{detail.series}}</div>
            <div v-if="!miniShow" class="info-start_point">{{ $t('order.flight number') }} : {{detail.filght_number}}</div>
          </div>
          <div  v-if="detail.status == 1"  class="info-box info-box3-4">
            <div class="edit-icon" @click="alertOrderInfo()">
              <img src="/images/icon/icon-order-edit.png">
            </div>
            <div class="info-start_point">{{ $t('order.passenger') }} : {{detail.passenger_name}}</div>
            <div class="info-start_point info-start_point2">{{ $t('order.passengers') }} : <van-stepper v-model="detail.passenger_num" min="1" :max="maxSeat" button-size="24px" disable-input @change="changePassengersNum"/></div>
            <div class="info-start_point info-start_point2">{{ $t('order.luggages') }} : <van-stepper v-model="detail.luggages_num" min="0" :max="maxSeat" button-size="24px" disable-input @change="changeLuggagesNum"/></div>
           </div>
          <div  v-else-if="detail.status > 1"  class="info-box info-box3-4_2">
            <div class="info-start_point">{{ $t('order.passenger') }} : {{detail.passenger_name}}</div>
            <div class="info-start_point">{{ $t('order.passengers') }} : {{detail.passenger_num}}</div>
            <div class="info-start_point">{{ $t('order.luggages') }} : {{detail.luggages_num}}</div>
           </div>
          <div class="info-box info-box4">
            <div class="info-ordernumber">{{ $t('common.ordernumber') }} : {{detail.ordernumber}}</div>
          </div>
          <div class="info-box info-box5">
            <div class="box5-1"  v-if="detail.status!=0">
              <div class="b5-1-price">{{detail.price}}<span class="b5-1-d">{{ $t('common.¥') }}</span></div>
            </div>
            <div class="box5-2 btn-list">
              <div class="btn-box">
                <div class="btn-item cancel-btn" v-if="detail.status==2" @click="alertCancelShow">{{ $t('common.cancel order') }}</div>
                <div class="btn-item pay-btn"  v-if="detail.status==1" @click="prepaySub">{{ $t('common.to pay') }}</div>
                <div class="chauffeur-info" v-if="detail.status>=3">
                  <div class="chauffeur-header">
                    <img :src="icon.chauffeurIcon">
                  </div>
                  <div class="chauffeur-show">
                    <div class="c-info">{{detail.chauffeur_name}}</div>
                    <div class="c-info">{{detail.chauffeur_phone}}</div>
                  </div>
                </div>
              </div>
              <div class="btn-box" @click="showCustom = true">
                <div class="btn-item custom-btn">{{ $t('order.phone service') }}</div>
              </div>

              <div class="box5-3"  v-if="detail.status==0">
                <div class="b5-3-1">{{ $t('order.Cancel successful') }}</div>
                <div class="b5-3-2">{{ $t('order.can rebook') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <van-popup id="wx-customer" v-model="showCustom" :close-on-click-overlay="false">
        <div class="wx-customer-container">
          <div class="close-btn">
            <img class="x-icon" :src="icon.close" @click="showCustom = false"/>
          </div>
          <div class="icon-img-box">
            <img class="icon-img" :src="icon.customerIcon">
          </div>
<!--          <a class="customer-info" :href="'tel:'+hotelPhone">{{ $t('common.Hotel phone') }} : <span>{{hotelPhone}}</span></a>-->
          <a class="customer-info customer-info2"  :href="'tel:'+servicePhone">{{ $t('common.Customer phone') }} : <span>{{servicePhone}}</span></a>
        </div>
      </van-popup>

      <van-overlay :show="mapShow"  class="overlay-div">
        <div class="map-container">
          <div class="map-box">
            <TheMap class="map" @selectLocation="selectLocation" @closeAlert="closeAlert"></TheMap>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="changeOrderShow">
        <div class="overlay-div"></div>
        <div class="order-info-container">
          <div class="order-info-box">
            <div class="order-info-show">
              <div class="order-info">
                <div class="oi-close" @click="closeChangeOrderInfo">
                  <img src="/images/icon/act-icon-x.png">
                </div>
                <div class="subForm">
                  <div class="oi-head">{{$t('order.passenger info')}}</div>
                  <div class="oi-item">
                    <div class="oi-name">{{$t('order.passenger')}} :</div>
                    <input class="oi-item-input" v-model="detail2.passenger_name" :placeholder="detail.passenger_name">
                  </div>
                  <div class="oi-item">
                    <div class="oi-name">{{$t('order.phone')}} :</div>
                    <input class="oi-item-input" v-model="detail2.passenger_phone" readonly>
                  </div>
                  <div class="oi-sub-container">
                    <div class="oi-sub-btn" @click="subOrderInfoChange">{{$t('common.confirm')}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-overlay>

      <van-overlay :show="loadShow"  class="overlay-div">
        <van-loading type="spinner"  class="load"/>
      </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast, List, Dialog,Stepper} from 'vant';
    import TheMap from "@/components/TheMap.vue";
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(List).use(Dialog).use(Stepper);
    export default {
        name: 'transfer',
        components: {TheMap},
        data() {
            return {
              loadShow: false,
              cancelShow: true,
              orderid: 0,
              detail: {
                    ordernumber: '-',
                    filght_number: '-',
                    series: '-',
                    type: 1,
                    type_text: '-',
                    status: .0,
                    status_text: '-',
                    appointment: '-年-月-日  -:-',
                    start_point: '-',
                    end_point: '-',
                    price : '-.-',
                    icon: 'b2',
                    chauffeur_name: '-',
                    chauffeur_phone : '-',
                    passenger_num: 1,
                    luggages_num: 0
                  },
              detail2 : {
                chauffeur_name: '-',
                chauffeur_phone : '-',
              },
              bannerList: [
                  '/images/order/banner-detail@3x.jpg'
              ],
              icon: {
                chauffeurIcon: '/images/order/icon-member@3x.jpg',
                customerIcon: '/images/order/icon-order-customer.png',
                close: '/images/icon/if-close 1@3x.png'
              },
              navIndex: 1,
              showCustom: false,
              hotelPhone: '400-673-7888',
              servicePhone: '400-673-7888',
              buttonLock: false,
              mapShow : false,
              miniShow: false,
              changePointForm : {
                type : 1
              },
              maxSeat : 1,

              changeOrderShow: false
            }
        },
        created: function () {
          var query = this.$route.query;
          if(query.id == null || query.id == 0 || query.id == undefined){
            Toast('订单信息异常');
            this.$router.replace('/order_list');
          }
          this.orderid = query.id;

          this.loadOrderDetail();


        },
        methods: {
          loadOrderDetail(){
            var that = this;
            this.loadShow = true;
            this.$post('/getUserOrderDetail', {
              orderid : this.orderid,
              uid : this.$store.getters['user/getUserId'],
              'lang' : this.$store.getters['local/getLanguages']
            }).then((res) => {
                if(res.code == 1000){
                  var data = res.data;
                  var detail = {
                    ordernumber: data.order_number,
                    filght_number: data.flight_number,
                    series: data.car_series,
                    type: data.item_id,
                    type_text: data.itemname,
                    status: data.order_status,
                    status_text: data.state,
                    appointment: data.usetime,
                    start_point: data.origin,
                    end_point: data.end,
                    price : data.price,
                    chauffeur_name: data.chauffeur_name,
                    chauffeur_phone : data.car_number,
                    item_id : data.item_id,
                    passenger_name: data.passenger_name,
                    passenger_phone: data.passenger_phone,
                    passenger_num: this.$isset(data.passenger_people) ? data.passenger_people : 1,
                    luggages_num : data.luggage,
                  }

                  if(data.passenger_phone.length >= 11){
                    var hiddenPhone = data.passenger_phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
                  }

                  this.detail2 = {
                    passenger_name : detail.passenger_name,
                    passenger_phone : hiddenPhone,
                  }
                  if(data.passenger_name == data.passenger_phone){
                    detail.passenger_name = hiddenPhone;
                    this.detail2.passenger_name = '';
                  }

                  this.maxSeat = data.seat.overrun;
                  this.detail = detail;
                  this.servicePhone = res.data.wxmobile;
                  this.hotelPhone = res.data.hotelmobile;
                  this.hotelPhone= res.data.hotelmobile;
                  this.servicePhone = res.data.butlermobile;

                  //包车类别
                  var itemList = [39,40,41];
                  if(itemList.indexOf(data.item_id) != -1){
                    this.miniShow = true;
                  }
                }else{
                  Toast(res.msg);
                  setTimeout(function(){
                    that.$router.replace('/order_list')
                  },1000);
                }
            }).finally(()=>{
              this.loadShow = false;
            })
          },
          /**
           * 更改地址
           */
          subOrderPointChange(key, point){
            var that = this;
            this.loadShow = true;
            return this.$post('/reviseOrginOrEnd', {
              address : key,
              position : point,
              uid : this.$store.getters['user/getUserId'],
              orderid : that.orderid,
              type : that.changePointForm.type
            });
          },
          /**
           * 更改信息
           */
          subOrderInfo(){

          },
          /**
           * 更改订单乘车人数目
           * @param e
           * @returns {Promise | Promise<any>}
           */
          subPassengerNum(e){
            var that = this;
            return this.$post('/addPassenger_people', {
              uid : this.$store.getters['user/getUserId'],
              orderid : that.orderid,
              'passenger_people' :e
            });
          },
          subLuggagesNum(e){
            var that = this;
            return this.$post('/addLuggage', {
              uid : this.$store.getters['user/getUserId'],
              orderid : that.orderid,
              'luggage' :e
            });
          },
          /**
           * 提交订单信息更改
           */
          subOrderInfoChange(){
            var that = this;
            if(that.detail2.passenger_name == ''){
              Toast('请填入用户名');
              return false;
            }
            that.loadShow = true;
            return this.$post('/addRecording', {
              uid : this.$store.getters['user/getUserId'],
              orderid : that.orderid,
              passenger_name: that.detail2.passenger_name,
              passenger_people: that.detail.passenger_num,
              luggage: that.detail.luggages_num,
              gender: ''
            }).then((res)=>{
              if(res.code == 1000){
                that.changeOrderShow = false;
                that.detail.passenger_name = that.detail2.passenger_name;
              }else{
                Toast(res.msg);
              }
            }).catch((e)=> {
              Toast('订单提交异常，请联系管理员');
              console.log(e);
            }).finally(()=>{
              that.loadShow = false;
            });
          },
          getHotelPhone(){
            var hotelId = this.$store.getters['user/getHotelId']
              this.$post('/getHotelMobile', {
                hotelid : hotelId
              }).then((res)=>{
                if(res.code == 1000){
                  this.servicePhone = res.data.wxmobile;
                  this.hotelPhone = res.data.hotelmobile;
                }
              })
          },
          alertCancelShow(){
            if(!this.$loginWXType()){
              return false;
            }

            Dialog.confirm({
              message: '确认取消订单'
            }) .then(() => {
              // on confirm
              this.cancelOrder();
            })
            .catch(() => {
              // on cancel
            });
          },
          alertCancelClose(){
          },
          cancelOrder(){
            this.loadShow = true;
            var that = this;
            this.$post('/cancelUserPayOrder', {
              uid : this.$store.getters['user/getUserId'],
              orderid : this.orderid,
              openid : this.$store.getters['user/getOpenId'],
            }).then((res)=>{
              if(res.code == 1000){
                Toast(res.msg);
                setTimeout(function(){
                  that.loadOrderDetail();
                })
              }else{
                Toast(res.msg);
              }
            }).catch((e)=>{
              Toast(e.msg)
            }).finally(()=>{
              this.loadShow = false;
            })
            Toast('订单取消成功');
          },
          //支付启动
          prepaySub: function(){
            if(!this.$loginWXType()){
              return false;
            }
            var that = this;
            let orderId = this.orderId;
            if(this.buttonLock){
              Toast('操作频繁，请稍后重试')
            }
            this.buttonLock = true; //上锁不让操作
            this.loadShow = true;
            let data = {
              'orderid' : this.orderid,
              'openid' : this.$store.getters['user/getOpenId'],
              'uid' : this.$store.getters['user/getUserId'],
            };

            this.$post('/perPayOrder', data).then((res) => {
              if(res.code == 1000){
                let data = res.data;

                wx.ready(function(){
                  wx.chooseWXPay({
                    timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                    nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
                    package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                    paySign: data.sign, // 支付签名
                    success: function (res) {
                      console.log('支付成功');
                      Toast('支付成功');
                      setTimeout(that.loadOrderDetail(), 200);//信息刷新
                      that.buttonLock = false;
                      that.loadShow = false;
                    },
                    complete: function (res) {
                      that.buttonLock = false;
                      that.loadShow = false;
                    },
                    cancel: function (res) {//提示引用的是mint-UI里toast
                      that.loadShow = false;
                      console.log('已取消支付');
                      that.buttonLock = false;
                    },
                    fail: function (res) {
                      that.loadShow = false;
                      console.log('支付失败，请重试');
                      that.buttonLock = false;
                    }
                  })
                })
              }
            });
          },
          //改变起点终点
          changePoint: function(type){
            var itemId = this.detail.item_id;
            //包车类别
            var itemList = [39,40,41];
            //必须是未支付并且包车类才行
            if(this.detail.status == 1 && itemList.indexOf(itemId)  != -1){
              this.mapShow = true;
              if(type == 1){
                // 起点
                this.changePointForm.type = 1;
              }else if(type == 2){
                // 终点
                this.changePointForm.type = 2;
              }
            }
          },
          // 选择地点
          selectLocation: function(res){
            if(res.lng == '' || res.lat == ''){
              Toast('请选择填入地址');
              return false;
            }

            if(this.changePointForm.type == 1){
              //上车点
              this.detail.start_point = res.key;
            }else if(this.changePointForm.type == 2){
              //下车点
              this.detail.end_point = res.key;
            }
            var point = res.lng + ',' + res.lat;

            //提交给远端
            this.subOrderPointChange(res.key, point).then((res) => {
              //处理后续
              if(res.code == 1000){

              }else{
                Toast(res.msg);
              }
              this.mapShow = false;
              this.loadShow = false;
            }).catch((e) => {
              Toast('订单操作异常请稍后重试');
              console.log(e);
            });
          },
          // 选择地点
          closeAlert: function(res){
            this.mapShow = false;
          },
          /**
           * 弹窗修改订单信息
           */
          alertOrderInfo(){
            this.changeOrderShow = true;
          },
          closeChangeOrderInfo(){
            this.changeOrderShow = false;
          },
          changePassengersNum(e){
            this.subPassengerNum(e);
          },
          changeLuggagesNum(e){
            this.subLuggagesNum(e);
          }
        },
    }
</script>

<style>
  #order_detail-container{
    padding-bottom: env(safe-area-inset-bottom);
    background: #F9F8F8;
    min-height: 100%;
    width: 100%;
    height: 100%;
  }
  #order_detail-container .banner-list{
    width: 100%;
  }
  #order_detail-container .banner-list .banner-item,#order_detail-container .banner-list img{
    width: 100%;
  }

  #order_detail-container .detail-info-container{
    width: 100%;
    background: #fff;
    height: 78%;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 25px 25px 0 0;
    padding: 6PX 20px;
    padding-top: 16px;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    color: #6D5246;
    padding-bottom: env(safe-area-inset-bottom);
  }
  #order_detail-container .detail-info-container .info-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  #order_detail-container .detail-info-container .info-box{
    border-bottom: 1px solid #BBBBBB;
    padding: 16px 4px;
    position: relative;
  }
  order_detail-container .detail-info-container .info-box1{
    padding-top: 0;
  }

  #order_detail-container .detail-info-container .info-status_text{
    height: 16px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #order_detail-container .detail-info-container .info-status_text.hidden{
    display: none;
  }
  #order_detail-container .detail-info-container .info-box2{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #order_detail-container .detail-info-container .point-box{
    padding-left: 36px;
    width: 100%;
    height: 100%;
    position: relative;
  }
  #order_detail-container .detail-info-container .point-box .point-info-mini{
    color: #8a8a8c;
    font-size: 12px;
    padding-left: 20px;
  }
  #order_detail-container .detail-info-container .info-box2 .left-line{
    width: 6px;
    height: 100%;
    background: linear-gradient(#EAADAD 0%, #B7BAEA 100%);
    position: absolute;
    left: 12px;
    border-radius: 50px;
  }
  #order_detail-container .detail-info-container .info-box2 .info-middle{
    height: 40px;
  }
  #order_detail-container .detail-info-container .info-box3{
    padding: 10px 0;
  }
  #order_detail-container .detail-info-container .info-box3-4{
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  #order_detail-container .detail-info-container .info-box3-4 .info-start_point{
    line-height: 24px;
    position: relative;
    padding-bottom: 10px;
  }
  #order_detail-container .detail-info-container .info-box3-4 .info-start_point .van-stepper{
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
  }
  #order_detail-container .detail-info-container .info-box3-4 .info-start_point2{
    line-height: 32px;
    padding-bottom: 0;
  }

  #order_detail-container .detail-info-container .info-box3-4 .edit-icon{
    width: 14px;
    height: 14px;
    position: absolute;
    padding: 8px;
    right: 8px;
    top: 10px;
    z-index: 20;
    box-sizing: content-box;
  }

  #order_detail-container .detail-info-container .info-box3-4_2 .info-start_point{
    line-height: 22px;
  }

  #order_detail-container .detail-info-container .info-box2,#order_detail-container .detail-info-container .info-box3{
    line-height: 24px;
  }

  #order_detail-container .detail-info-container .info-box5{
    border: none;
    position: relative;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-1{
    font-size: 28px;
    line-height: 28px;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-1 span{
    font-size: 18px;
  }
  #order_detail-container .detail-info-container .info-box5 .btn-list{
    display: flex;
    margin-top: 20px;
  }
  #order_detail-container .detail-info-container .info-box5 .btn-list .btn-item{
    width: 125px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    border-radius: 8px;
    text-align: center;
    background: #000;
    color: #fff;
    margin: auto;
  }
  #order_detail-container .detail-info-container .info-box5 .btn-list .btn-box{
    width: 50%;
  }
  #order_detail-container .detail-info-container .info-box5 .btn-list .cancel-btn{
    background: #DEDBDB;
  }
  #order_detail-container .detail-info-container .info-box5 .btn-list .custom-btn{
    background: #B98A73;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-2{
    position: relative;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-3{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 40px;
    line-height: 20px;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-3 .b5-3-1{
    font-size: 18px;
  }
  #order_detail-container .detail-info-container .info-box5 .box5-3 .b5-3-2{
    font-size: 12px;
    color: rgba(0,0,0,0.4);
  }

  #order_detail-container .detail-info-container .chauffeur-info{
    background: #fff;
    box-shadow: 0px 2px 10px #BBB;
    border-radius: 10px;
    position: relative;
    padding: 8px;
    padding-left: 56px;
    max-width: 156px;
    transform: translateY(-20%);
    font-size: 12px;
    white-space: nowrap;
    min-height: 52px;
  }
  #order_detail-container .detail-info-container .chauffeur-info .chauffeur-header{
    width: 39px;
    height: 39px;
    border-radius: 50px;
    overflow: hidden;
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #order_detail-container .detail-info-container .chauffeur-info .chauffeur-header img{
    width: 100%;
    height: 100%;
  }
  #order_detail-container .detail-info-container .chauffeur-info .chauffeur-show{
    display: inline-block;
  }

  #order_detail-container .wx-customer-container{
    background: #fff;
    color: #B98A73;
    font-size: 14px;
    width: 257px;
    padding: 40px 20px;
    position: relative;
  }
  #order_detail-container .close-btn{
    width: 14px;
    height: 14px;
    top: 12px;
    right: 12px;
    position: absolute;
  }
  #order_detail-container .wx-customer-container .icon-img-box{
    width: 86px;
    margin: auto;
  }
  #order_detail-container img{
    width: 100%;
  }
  #order_detail-container .customer-info{
    line-height: 30px;
    display: block;
    color: #B98A73;
  }


  #order_detail-container .map-container{
    padding: 0 30px;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
  #order_detail-container .map-container .map-box{
    position: relative;
    height: 100%;
    width: 100%;
  }
  #order_detail-container .map-container .map{
    position: absolute;
    width: 100%;
    z-index: 20;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #fff;
  }

  #order_detail-container .order-info-container{
    padding: 0 30px;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }

  #order_detail-container .order-info-container .order-info-box{
    position: relative;
    height: 100%;
    width: 100%;
  }
  #order_detail-container .order-info-show{
    position: absolute;
    z-index: 20;
    top: 50%;
    left: 50%;
    margin: auto;
    background: #fff;
    transform: translate3d(-50%, -50%, 0);
    padding: 20px;
    min-width: 80%;
    border-radius: 8px;
  }
  #order_detail-container .order-info-show .oi-item{
    padding: 8px 4px;
    font-size: 14px;
    text-align: left;
    position: relative;
    padding-left: 77px;
  }
  #order_detail-container .order-info-show .oi-name{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    line-height: 20px;
    height: 20px;
  }
  #order_detail-container .order-info-show .oi-item-input{
    width: 100%;
  }
  #order_detail-container .order-info-show .oi-close{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
  }
  #order_detail-container .order-info-show .oi-head{
    padding:  10px 0;
  }
  #order_detail-container .order-info-show .oi-sub-container{
    padding-top: 10px;
  }
  #order_detail-container .order-info-show .oi-sub-btn{
    width: 100%;
    line-height: 30px;
    background: #B98A73;
    color: #fff;
  }
</style>
