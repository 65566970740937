module.exports = {
    common : {
        phone : '手机号',
        verificationCode : '验证码',
        submit: '提交',
        'Please enter your phone number' : '请输入手机号',
        'Please enter your code' : '请填写验证码',
        'Please enter code' : '填入图形码',
        'get code' : '获取验证码',
        'confirm' : '确认',
        'cancel' : '取消',
        'service agreement' : '用户协议',
        'this service is provided by ParkLane' : '本服务由 威信出行 提供',
        'Drivers will prioritize contacting passengers' : '司机优先联系乘车人',
        'Select car series':'选择车系',
        'cancel order' : '取消订单',
        'to pay' :'确认支付',

        'ordernumber':'订单号',
        'Hotel phone' : '酒店电话',
        'Customer phone' :'管家电话',
        '¥' : '元',
        'city Temporarily Closed' : '暂未开放其他城市',
        'Please choose city' : '请选择使用城市',

        'order list' : '订单列表',
        'logout' : '登出',
        'user time' : '用车时间'
    },
    index : {
        'Please login first' : '请先登录',
        'Login now' : '登录',
        'Exclusive Services' : '专属服务'
    },
    service : {
        'welcome to use the function' : '欢迎使用，为你关心的TA代叫',
        'pick-up': '接机',
        'drop-off' : '送机',
        'professional and reassuring services' : '我们将提供你专业的安心服务',
        'Please enter flight number' :'请输入您的航班号 例：CA8371',
        'Please enter your end' : '输入你的目的地',
        'Starting from' : '你将从',
        'go on' : '上车',
        'select airport' : '请选择前往的机场',
        'select date' : '请选择日期',
        'select time' : '选择用车时间',
        'luxury car' :'豪华车',
        'business Van' :'商务车',
        'our 24-hour service line' : '如有特殊需求，请拨打客服电话',
        'hotel' : '酒店列表',
        '(local time)' : '（起点当地时间）',
        'Please enter phone number' :'输入乘车人手机号',
        'passenger' :'乘车人',
        '2 hours' : '2小时',
        '4 hours' : '4小时',
        '8 hours' : '8小时',
        'shanghai' : '上海',
        'choose city' : '切换其他城市',
        'no More Sereis' : '未有可选车系，请联系客服'
    },
    order : {
        'time' : '用车时间',
        'start' : '起',
        'end' : '终',
        'start2' : '出发地点',
        'end2' : '到达地点',
        'service type' : '用车类型',
        'car series' : '预定车系',
        'flight number' : '航班/车次',
        'Cancel successful' : '订单取消成功',
        'can rebook' : '你可以重新预定',
        'phone service' : '联系管家',
        'no more' : '没有更多了',
        'passenger info' : '乘车人信息',
        'passenger' : '乘车人',
        'phone' : '手机号',
        'passengers' : '同行人数',
        'luggages' : '行李数',
        'Click to change location' : '点击改变地点'
    },
    charter: {
        'fine drive': '优质司机',
        'fine drive mini': '精选司机服务',
        'Million yuan insurance': '百万保险',
        'Million yuan insurance mini': '平台赠送保险',
        'Quick invoicing': '极速开票',
        'Quick invoicing mini': '便捷电子开票',
    },
    transfer : {
        'Flight delay waiting': '航班延误免费等',
        'Flight delay waiting mini': '专车尊享',
        'Help guest to take luggage': '提拿行李',
        'Help guest to take luggage mini': '豪华车尊享',
        'Dress uniform': '司机着装统一',
        'Dress uniform mini': '专车尊享',
        'Provide mineral water': '车内饮用水',
        'Provide mineral water mini': '专车尊享',
        'Power bank': '车内充电线',
        'Power bank mini': '专车尊享',
        'Collection service': '机场助理引导',
        'Collection service mini': '专车尊享',
        'Provide WiFi': '车内wifi',
        'Provide WiFi mini': '专车尊享',
        'Customized services': '专属服务',
        'Customized services mini': '定制化服务',
    }
}