/**
 * 订单共享信息
*/
const order = {
    namespaced: true,
    state : {
        city: '',
        hotelAddress: '',
        time: 0,
        start: '',
        startPoint: '',
        end: '',
        endPoint: '',
        hotelId : '',
        flightNumber : '',
        itemId : 0,
    },
    mutations: {
        //存储token方法
        //设置token等于外部传递进来的值
        setCity(state, city){
            state.city = city;
            sessionStorage.city = city //同步存储token至localStorage
        },
        setHotelAddress(state, hotelAddress){
            state.hotelAddress = hotelAddress;
            sessionStorage.hotelAddress = hotelAddress //同步存储token至localStorage
        },
        setTime(state, time){
            state.time = time;
            sessionStorage.time = time //同步存储token至localStorage
        },
        setStart(state, start){
            state.start = start;
            sessionStorage.start = start //同步存储token至localStorage
        },
        setStartPoint(state, startPoint){
            state.startPoint = startPoint;
            sessionStorage.startPoint = startPoint //同步存储token至localStorage
        },
        setEnd(state, end){
            state.end = end;
            sessionStorage.end = end //同步存储token至localStorage
        },
        setEndPoint(state, endPoint){
            state.endPoint = endPoint;
            sessionStorage.endPoint = endPoint //同步存储token至localStorage
        },
        setFlightNumber(state, flightNumber){
            state.flightNumber = flightNumber;
            sessionStorage.flightNumber = flightNumber //同步存储token至localStorage
        },
        setHotelId(state, hotelId){
            state.hotelId = hotelId;
            sessionStorage.hotelId = hotelId //同步存储token至localStorage
        },
        setItemId(state, itemId){
            state.itemId = itemId;
        }
    },
    getters : {
        getHotelAddress(state) {
            if (!state.hotelAddress) {
                state.hotelAddress = sessionStorage.getItem('hotelAddress');
            }
            return state.hotelAddress
        },
        getCity(state) {
            if (!state.city) {
                state.city = sessionStorage.getItem('city');
            }
            return state.city
        },
        getTime(state) {
            if (!state.time) {
                state.time = sessionStorage.getItem('time');
            }
            return Number(state.time);
        },
        getStart(state) {
            if (!state.start) {
                state.start = sessionStorage.getItem('start');
                if(!state.start){
                    state.start = '';
                }
            }
            return state.start
        },
        getStartPoint(state) {
            if (!state.startPoint) {
                state.startPoint = sessionStorage.getItem('startPoint');
                if(!state.startPoint){
                    state.startPoint = '';
                }
            }
            return state.startPoint
        },
        getEnd(state) {
            if (!state.end) {
                state.end = sessionStorage.getItem('end');
                if(!state.end){
                    state.end = '';
                }
            }
            return state.end
        },
        getEndPoint(state) {
            if (!state.endPoint) {
                state.endPoint = sessionStorage.getItem('endPoint');
                if(!state.endPoint){
                    state.endPoint = '';
                }
            }
            return state.endPoint
        },
        getFlightNumber(state) {
            if (!state.flightNumber) {
                state.flightNumber = sessionStorage.getItem('flightNumber');
            }
            return state.flightNumber
        },
        getHotelId(state) {
            if (!state.hotelId) {
                state.hotelId = sessionStorage.getItem('hotelId');
            }
            return state.hotelId
        },
        getItemId(state){
            return state.itemId;
        }
    },
    actions: {

    }
};

export default  order;