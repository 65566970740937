<template>
    <div class="map" id="map-core2-container">
      <div class="alert-show"></div>
      <div id="map-core2"></div>
    </div>
</template>

<script>
    /* eslint-disable */
    import xCoordYellowIcon from "@/assets/X-coord-yellow.png";
    import xCoordRedIcon from "@/assets/X-coord-red.png";

    export default {
        name: 'AddressSearch',
        data () {
            return {
              showPoints: ''
            }
        },
      props:{
        points : {
          type: String,
          default: ''
        }
      },
        mounted () {
            //直接用IP定位
            var that = this;
            function myFun(result){
                that.setMap(result.center.lng,result.center.lat);
            }

            //城市定位
            var myCity = new BMap.LocalCity();

            myCity.get(myFun);
            console.log('mounted');
        },
        created () {
          this.$parent.$on('changeMapInfo', (data) => { // 监听名为'data'的事件
            // console.log('回传数据'); // 输出传递的数据
            var that = this;
            that.location = data;

            that.map.clearOverlays();    //清除地图上所有覆盖物
            that.map.centerAndZoom(data, 13);
            that.map.addOverlay(new BMap.Marker(data));    //添加标注
          });
        },
        methods: {
            // 初始化地图
            setMap (lng, lat) {
                var that = this;
                function G(id) {
                    return document.getElementById(id);
                }
                this.map = new BMap.Map('map-core2');
                this.map.centerAndZoom(new BMap.Point(lng, lat), 15);
                this.map.enableScrollWheelZoom(false);
                this.map.disableDragging();
                this.mapDrawPoint();
            },

          mapDrawPoint(){
            var that = this;
              var iconRed = new BMap.Icon(xCoordRedIcon, new BMap.Size(46, 50),{
                // 指定定位位置。
                // 当标注显示在地图上时，其所指向的地理位置距离图标左上
                // 角各偏移10像素和25像素。您可以看到在本例中该位置即是
                // 图标中央下端的尖角位置。
                anchor: new BMap.Size(20, 50),
                imageSize: new BMap.Size(46, 50)
              });
              this.map.clearOverlays() //清空覆盖物
              var Position = this.points.split(',');

              var point = new BMap.Point(Position[0], Position[1]);
                // console.log(item);
                //选中的
              this.map.centerAndZoom(point, 14); //设置为中心
              var marker = new BMap.Marker(point,{icon: iconRed});
              this.map.addOverlay(marker);
          },
        },
        watch: {
          points (now, old) {
              this.mapDrawPoint();
          }
        }
    }
</script>

<style scoped>
  #map-core2-container{
    position: relative;
  }
  .map #map-core2 {
    width: 100%;
    height: 300px;
    margin: 0;
  }
  .map .alert-show{
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0,0,0,0.1);
    z-index: 10;
  }
</style>