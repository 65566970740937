<template>
    <div id="order_list-container">
        <!--    页面主题-->
      <div class="order-list-box">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('order.no more')"
            @load="onLoad"
            class="order-list">
          <div class="order-item" v-for="(item,index) in list" :class="item.status== 3 ? 'item-gold' : '' " @click="navTodetail(item.order_id)">
            <div class="item-top">
              <div class="type-box">
                <img class="type-icon" :src="icon[item.icon]">
                <div class="type-content">{{item.type_text}}</div>
              </div>
              <div class="status-box">
                <div class="status-content">{{item.status_text}}</div>
              </div>
            </div>
            <div class="item-box">
              <div class="item-box-left">
                <div class="left-box">
                  <div class="item-appointment">{{ $t('order.time') }} : {{item.appointment}}</div>
                  <div class="item-start_point">{{ $t('order.start2') }} : {{item.start_point}}</div>
                  <div class="item-end_point">{{ $t('order.end2') }} : {{item.end_point}}</div>
                </div>
              </div>
              <div class="item-box-right">
                <div class="price-box">
                  <div class="price-content">￥{{item.price}}</div>
                </div>
              </div>
            </div>
          </div>
        </van-list>

        <BottomNav></BottomNav>
      </div>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast,List} from 'vant';
    import BottomNav from "@/components/BottomNav.vue";
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(List);
    export default {
        name: 'transfer',
      components: {BottomNav},
        data() {
            return {
              list: [],
              icon: {
                b2: '/images/icon/icon-2-black@3x.png',
                g2: '/images/icon/icon-2-gold@3x.png',
                b4: '/images/icon/icon-4-black@3x.png',
                g4: '/images/icon/icon-4-gold@3x.png',
                b8: '/images/icon/icon-8-black@3x.png',
                g8: '/images/icon/icon-8-gold@3x.png',
                bai: '/images/icon/icon-airin-black@3x.png',
                gai: '/images/icon/icon-airin-gold@3x.png',
                bao: '/images/icon/icon-airout-black@3x.png',
                gao: '/images/icon/icon-airout-gold@3x.png',
                bo: '/images/icon/icon-car-black@3x.png',
                go: '/images/icon/icon-car-gold@3x.png',
              },
              loading: false,
              finished: false,
              pageIndex : 1,
              pageSize : 7
            }
        },
        created: function () {
          //加载列表
          this.loadOrdList(this.pageIndex).then((data) => {
            this.list = data.list;
            this.pageIndex = data.page;
          });
        },
        methods: {
          onLoad() {
            // 异步更新数据更多数据
            if(!this.finished && !this.isLoading){
              setTimeout(() => {
                let pageIndex = this.pageIndex;
                pageIndex++;
                this.loadOrdList(pageIndex).then((data) => {
                  let list = this.list;
                  this.list = list.concat(data.list);
                  this.pageIndex = data.page;
                });
              }, 500);
            }
          },
          loadOrdList ($pageIndex){
            var that = this;
            this.loading=true;
            this.isLoading = true;
            return new Promise((resolve) => {
              let data = {
                'uid' : this.$store.getters['user/getUserId'],
                'pageIndex' : $pageIndex,
                'pageLimit' : this.pageSize,
                'lang' : this.$store.getters['local/getLanguages']
              };
              this.$post('/getUserOrderList', data).then((res) => {
                if(res.code == 1000){
                  let len = res.data.list.length;
                  var oList = res.data.list;
                  var list = [];
                  if(len > 0){
                    for (var i = 0; i < len ; i++){
                      var oItem = oList[i];
                      var item =
                          {
                            order_id : oItem.orderid,
                            type: oItem.item_id,
                            type_text: oItem.servicetype,
                            status: oItem.order_status,
                            status_text: oItem.state,
                            appointment: oItem.usetime,
                            start_point: oItem.origin,
                            end_point: oItem.end,
                            price : oItem.price,
                          }
                      item['icon'] = that.setType(item.type, item.status)
                      list.push(item);
                    }
                  }
                  res.data['list'] = list;
                  resolve(res.data);
                  if(res.data['pageCount'] < that.pageSize){
                    this.finished = true;
                  }else{
                    this.finished = false;
                  }
                }
                this.loading = false;
                this.isLoading = false;
              });
            })
          },
          setType : function(type, status){
                //已接单颜色变化
                var color = status == 3 ? 'g' : 'b';
                switch (type){
                  case 41:
                    return color + '2';
                  case 40:
                    return color + '4';
                  case 39:
                    return color + '8';
                  case 26:
                    //接机
                    return color + 'ai';
                  case 27:
                    //送机
                    return color + 'ao';
                  default:
                    return color + 'o';
                }
            },
          navTodetail(orderId){
            var path = '/order_detail?id='+orderId;
            this.$router.push({
                path : path
            })
          }
        }
    }
</script>

<style>
  #order_list-container{
    padding-bottom: env(safe-area-inset-bottom);
    background: #F9F8F8;
    min-height: 100%;
    width: 100%;
    padding-top: 10px;
  }
  #order_list-container .order-list{
    padding: 0 30px;
    width: 100%;
  }
  #order_list-container .order-item{
    background: #fff;
    margin-top: 10px;
    box-shadow: 0px 2px 6px #BBB;
    border-radius: 5px;
    position: relative;
    padding: 0 8px;
    color: #101010;
  }
  #order_list-container .order-item .item-top{
    position: relative;
    border-bottom: 1px solid #101010;
    height: 40px;
    line-height: 40px;
  }
  #order_list-container .order-item .item-top .type-box{
    text-align: left;
    font-size: 14px;
    padding-left: 44px;
  }
  #order_list-container .order-item .item-top .type-box .type-icon{
    width: 24px;
    height: 24px;
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  #order_list-container .order-item .item-top .status-box{
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    color: #B4ABAB;
    font-weight: bold;
  }

  #order_list-container .order-item .item-box{
    font-size: 12px;
    padding: 20px 10px;
    padding-top: 10px;
    text-align: left;
    color: #B4ABAB;
    position: relative;
  }
  #order_list-container .order-item .item-box .left-box{
    line-height: 20px;
    font-weight: bold;
  }

  #order_list-container .order-item .item-box .left-box div{
    white-space: nowrap;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
    padding-right: 24%;
  }
  #order_list-container .order-item .item-box .item-box-right{
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 20px;
    color: #6D5246;
  }


  /*选中状态*/
  #order_list-container .order-item.item-gold .item-top .type-box{
    color: #B98A73;
  }
  #order_list-container .order-item.item-gold .item-top .status-box{
    color: #E2CEC4;
  }
  #order_list-container .order-item.item-gold .item-box{
    color: #E2CEC4;
  }
</style>
