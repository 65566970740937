<template>
    <div class="author">
    </div>
</template>

<script>
    export default {
        name: 'author',
        data() {
            return {}
        },
        created: function () {
            //触发 授权跳页
            var that = this;
            var code = this.$route.query.code;    //授权code
            var state = this.$route.query.state;  //携带参数
            var redirectUri = encodeURI(this.$wechat.baseUrl + decodeURI(this.$route.path)); //前往的页面
            var recordUrl = this.$store.getters['local/getUrl']; //记录地址

            if(!this.$isset(code)){
              //请求获取链接并且重定向到微信授权页面
              if(process.env.NODE_ENV == 'development'){
                //测试数据
                this.$store.commit('user/setOpenId', 'oBnHy5pm8-ZvBT_DkrALL6yVrDOc');
                this.$store.commit('user/setMobile', 0);
                //特殊跳转页面
                window.location.replace('/');
              } else if(process.env .NODE_ENV == 'production'){
                let authUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.$wechat.appId+'&redirect_uri='+redirectUri+'&response_type=code&scope=snsapi_base&state='+window.btoa(state)+'#wechat_redirect';

                window.location.replace(authUrl);
              }
            }else{
              //请求后台获取用户信息并且存入，然后跳转到对应页面
              window.console.log('===========code===========' + code);
              this.$post('/wxLogin', {
                code: code,
              }).then((res) => {
                if(res.code == 1000){
                  //去除后if内跳转无法生效
                  let data =res.data;
                  let openId = data.openid;

                  //更新数据
                  that.$store.commit('user/setOpenId', openId);
                  // state =JSON.parse(window.atob(state));

                  var recordUrl = that.$store.getters['local/getUrl']; //记录地址

                  if(!that.$isset(recordUrl) || recordUrl == that.$route.path){
                    //为空或者访问当前页面无限循环
                    window.location.replace('/');
                  }else{
                    window.location.replace(recordUrl);
                  }
                }else{
                  alert(res.msg);
                }
              }).catch(
                  (e) =>{
                    window.console.log(e);
                  }
              )
            }
        },
        methods: {}
    }
</script>

<style>
    .author {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }
</style>
