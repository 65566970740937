<template>
    <div class="login" id="login-container" :class="language">
        <!--    验证码主体-->
        <img class="login-bg" :src="bg"/>
        <div class="language-c" @click="changeLanguage">中文/English</div>
        <div class="container" :class="'container-' + this.hotelid">
          <div class="hotel-logo-show-container">
            <div class="hotel-logo-show">
              <img class="hotel-logo" :src="hotelLogo">
            </div>
          </div>
            <!-- 路由对应组件的显示 -->
            <div class="source">
                <div class="login-title">{{$t('index.Please login first')}}</div>
                <form class="container" @submit.prevent="submitCustCode">
                  <div class="submit-content-input submit-content-input2">
                    <input class="input" type="number" :placeholder="$t('common.Please enter your phone number')" v-model="logFormObj.mobile" @blur="clearTop"/>
                    <div class="code-btn" @click="showCaptchaAlert">{{$t(tokenBtnMsg)}}</div>
                  </div>
                  <div class="submit-content-input">
                    <input class="input" type="number" :placeholder="$t('common.Please enter your code')" v-model="logFormObj.token" @blur="clearTop"/>
                  </div>
                  <div class="checkbox-container">
                    <label class="label-z">
                      <input type="checkbox" class="check-inp-z" v-model="checkVal">
                      {{ $t('common.confirm') }}<div class="agreement" @click="alertUserRule">《{{ $t('common.service agreement') }}》</div>
                    </label>
                  </div>
                  <div class="btn-list">
                    <div class="line-gold"></div>
                    <div class="btn-item">
                      <img class="icon-item" :src="icon.wechatIcon">
                    </div>
                    <div class="line-gold"></div>
                  </div>
                  <van-button class="submit-btn" round type="info" size="large" native-type="submit">{{$t('index.Login now')}}</van-button>
                  <div class="mini-info">{{$t('common.this service is provided by ParkLane')}}</div>
                </form>
            </div>
        </div>


      <van-popup id="wx-captcha" v-model="showCaptcha" :close-on-click-overlay="false">
          <div class="wx-captcha-container">
            <div class="close-btn">
              <img class="x-icon" :src="icon.close" @click="showCaptcha = false"/>
            </div>
            <div class="captcha-img-box">
              <img class="captcha-img" :src="captcha.img" @click="loadCapthca">
            </div>
            <div class="captcha-form">
              <input class="form-input" v-model="captchaVal" :placeholder="$t('common.Please enter code')"/>
              <div class="sub-btm" :class="language" @click="subtCaptchaInfo">{{ $t('common.submit') }}</div>
            </div>
          </div>
      </van-popup>


      <van-popup id="wx-service" v-model="showService" :close-on-click-overlay="false">
        <div class="wx-service-container">
          <div class="close-btn" @click="showService = false">
            <img class="x-icon" :src="icon.close" @click="showCaptcha = false"/>
          </div>
          <iframe
              id="service-show"
              :src="serviceUrl"
              style="width: 100%; height: 100%"
          ></iframe>
        </div>
      </van-popup>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Toast} from 'vant';
    import store from "@/store";
    vue.use(Button).use(Overlay).use(Loading);
    export default {
        name: 'login',
        data() {
            return {
              icon:{
                wechatIcon: '/images/icon/wechat-icon.png',
                close: '/images/icon/if-close 1@3x.png'
              },
                loadShow : false,
                alertWarnContent : '',
                logFormObj: {
                    mobile: '',
                    token: ''
                },
              checkVal: '',
                tokenBtnMsg : 'common.get code',
                deadline : 60,
                btnDisable : false,
                btnDisable2 : false,
              bg:'/images/index2/login-bg.jpg',
              hotelLogo:'',
              hotelLogoDefaut:'/images/logo/parklane-logo.png',
              language: this.$store.getters['local/getLanguages'],
              captcha : {
                key: '',
                img : '',
              },
              captchaVal : '',
              showCaptcha: false,
              showService: false,
              serviceUrl: '/service_zh',
              hotelid : 0
            }
        },
        created: function () {
          this.loadLogo();
        },
        methods: {
          loadLogo(){
            //判断是否有qrcode
            var that = this;
            var qrCode = this.$store.getters['local/getQrCode'];
            if(this.$isset(qrCode)){
              //有qrcode
              this.$post('/decrypt2', {
                qrcode : qrCode
              }).then((res)=>{
                if(res.code == 1000){
                  this.$store.commit('user/setPid', res.data.pid);
                  this.$store.commit('user/sethotelId', res.data.hotelid);
                  this.hotelid = res.data.hotelid;
                  if(that.$isset(res.data.hotelimg)){
                    this.hotelLogo = res.data.hotelimg;

                    return false;
                  }
                }
                this.setHotelImg()
              }).catch((e)=>{
                console.log(e.msg);
              })
            }else{
              this.setHotelImg();
            }
          },
          //酒店id获取酒店logo
          setHotelImg(){
            //有无hotelid
            var hotelid = this.$store.getters['user/getHotelId'];

            if(!this.$isset(hotelid)){
              //无
              this.hotelLogo = this.hotelLogoDefaut;
              return false;
            }
            //更新酒店图
            this.$post('/getHotelAddress', {
              hotelid : hotelid
            }).then((res)=>{
              if(res.code == 1000){
                if(this.$isset(res.data.hotelimg)){
                  this.hotelLogo = res.data.hotelimg;
                  return false;
                }
                //其他的引用默认
                this.hotelLogo = this.hotelLogoDefaut;
              }
            }).catch((e)=>{
              console.log(e.msg);
            })
          },
          /**
           * 获取图形验证
           * @returns {boolean}
           */
          loadCapthca(){
            var that = this;
            if(this.btnDisable){
              return false;
            }
            this.btnDisable = true;
            this.$post('/getCaptcha', {}
            ).then((res) => {
              if(res.code == 1000){
                //存储
                that.captcha = res.data;
                that.btnDisable = false;
              }else{
                Toast(res.msg);
              }
            }).catch(e => {
              window.console.log(e);
            }).finally(() => {
              that.loadShow = false; //关闭lo
            });
          },
          /**
           * 提交邀请码并登录
           * @returns {boolean}
           */
          submitCustCode : function (){

            let mobile = this.logFormObj.mobile;
            if(!this.isPhone(mobile)){
              Toast('请填写手机号');
              return false
            }
            let code = this.logFormObj.token;
            if(code == ''){
              Toast('请填写验证码');
              return false
            }
            if(!this.checkVal){
              Toast('请确认用户协议');
              return false
            }

            var qrCode = this.$store.getters['local/getQrCode'];
            if(!this.$isset(qrCode)){
              qrCode = '';
            }

            this.loadShow = true;
            this.$post('/mobileLogin', {
              mobile: mobile,
              code :  code,
              qrcode :  qrCode,
            }).then((res) => {
                if(res.code == 1000){
                  var data = res.data;
                  this.$store.commit('user/setUserId', data.id);
                  this.$store.commit('user/setMobile', data.mobile);
                  this.$store.commit('user/setPid', data.pid);
                  this.$store.commit('user/sethotelId', data.hotelid);
                  this.$router.replace({
                    path : '/index'
                  });
                  //删除本地的
                  this.$store.commit('local/delQrCode');
                }else{
                  Toast(res.msg);
                }
            }).finally(()=>{
              this.loadShow = false;
            });
          },
          /**
           * 提交图形验证
           */
          subtCaptchaInfo(){
            if(this.btnDisable){
              return false;
            }
            this.btnDisable = true;
            let mobile = this.logFormObj.mobile;
            if(this.isPhone(mobile)){
              this.$post('/sendLoginCode', {
                mobile :  mobile,
                key :  this.captcha.key,
                captcha :  this.captchaVal,
                lang : this.language
              }).then((res) => {
                if(res.code == 1000){
                  //循环倒计时
                  Toast(res.msg);
                  this.showCaptcha = false; //关闭弹窗
                  this.tokenBtnMsg = this.deadline + 's';
                  this.int_btnClock = window.setInterval(() => {
                    if(this.deadline <= 0){
                      window.clearInterval(this.int_btnClock);
                      this.tokenBtnMsg = 'common.get code';
                      this.deadline = 60;
                      this.btnDisable = false;
                      return;
                    }
                    this.deadline--;
                    this.tokenBtnMsg = this.deadline + 's';
                  },1000);
                }else{
                  Toast(res.msg);
                  this.btnDisable = false;
                }
              }).catch(e => {
                window.console.log(e);
                Toast('网路异常请稍后重试');
                this.btnDisable = false;
              });
            }else{
              this.btnDisable = false;
            }
            return false
          },
          /**
           * 变更个中英文
           */
          changeLanguage: function(){
              if(this.language != 'zh'){
                this.language = 'zh';
              }else{
                this.language = 'en';
                this.$store.commit('local/setLanguages')
              }
            this.$i18n.locale = this.language;
            this.$store.commit('local/setLanguages',this.language);
          },
          /**
           * 弹出图形验证
           */
          showCaptchaAlert(){
            if(this.btnDisable){
              return false;
            }
            this.loadShow = true;

            let mobile = this.logFormObj.mobile;
            if(this.isPhone(mobile)){
              //打开图形码验证弹窗
              this.captchaVal = '';
              this.showCaptcha = true;
              //载入图形码
              this.loadCapthca();
            }else{
              this.loadShow = false;
              Toast('请填写正确的手机号');
            }
          },
          alertUserRule(){
            if(this.language == 'en'){
              this.serviceUrl = 'service_en';
            }else{
              this.serviceUrl = '/service_zh';
            }
            this.showService =true;
          },
          // 检查字符串是否为合法手机号码
          isPhone: function (str) {
              let reg = /^(1[3-9][0-9])[0-9]{8}$/;
              if (reg.test(str)) {
                  // console.log('手机号码格式输入正确');
                  return true;
              } else {
                  // console.log('请输入正确格式的手机号码');
                  return false;
              }
          },
          // 清空
          clearTop: function(){
              setTimeout(function(){
                  var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                  window.scrollTo(0, Math.max(scrollHeight - 1, 0));
              }, 100);
          }
        }
    }
</script>

<style>

    #login-container{
      position: relative;
      background: #EEEFF1;
      height: 100%;
      width: 100%;
    }
    #login-container .login-title{

    }

    #login-container .login-bg{
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
    }
    #login-container .language-c{
      position: absolute;
      right: 6px;
      top: 10px;
      font-size: 12px;
      color: #B4ABAB;
      padding-bottom: 20px;
      padding-left: 20px;
      z-index: 2000;
    }
    #login-container .container{
      padding-top: 0;
      z-index: 1;
      display: block;
      height: 100%;
      width: 100%;
      width: 300px;
    }
    #login-container .container-265{
      background: linear-gradient(180deg, rgba(255,255,255,0.6) 0, rgba(255,255,255,0.05) 35%, rgba(255,255,255,0) 35%);
    }
    #login-container .hotel-logo-show-container{
      opacity: 0;
      display: none;
    }
    #login-container .hotel-logo-show{
      width: 100%;
      height: 200px;
      position: relative;
    }
    #login-container .hotel-logo-show .hotel-logo{
      //height: 100%;
      max-width: 300px;
      max-height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
    /* 提交组件 */
    #login-container .source{
      z-index: 1;
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 50%;
      right: 0;
      transform: translate3d(-50%, -50%, 0);
      margin: auto;
      min-width: 300px;
    }
    #login-container .source .sub-item-title{
      text-align: left;
      font-size: 16px;
      color: #B98A73;
      line-height: 40px;
    }

    #login-container .source .submit-content-input{
      width: 100%;
      //border: 1px solid #B98A73;
      font-size: 16px;
      line-height: 40px ;
      height: 40px;
      padding: 0 4px;
      box-sizing: border-box;
      background: #fff;
      width: 300px;
      margin-top: 28px;
      border-radius: 8px;
    }
    #login-container .source .submit-content-input2{
      padding-right: 88px;
      border-radius: 0 4px 4px 0;
      position: relative;
      background: #fff;
    }
    #login-container .source .submit-content-input input{
      border: none;
      background: transparent;
      width: 100%;
      color: #B98A73;
    }
    #login-container .source .code-btn{
      //background: #B98A73;
      color: #B98A73;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      border-radius: 0 4px 4px 0;
      font-size: 12px;
      width: 80px;
      height: 40px;
      line-height: 40px;
      line-height: 36px;
      margin: auto;
    }

    #login-container .checkbox-container{
      padding: 10px 0;
      padding-top: 30px;
    }
    #login-container .checkbox-container .label-z{
      position: relative;
      display: block;
      text-align: left;
      padding-left: 30px;
      color: #B98A73;
      font-size: 12px;
    }
    #login-container .checkbox-container .label-z .agreement{
      color: #7C7CD5;
      display: inline;
    }
    #login-container .checkbox-container .check-inp-z{
      width: 16px;
      height: 16px;
      border: 2px solid #B98A73;
      border-radius: 5px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      appearance: none; /* none时可以自定义其外观 */
    }
    #login-container .checkbox-container .check-inp-z:after{
      content: '';
      width: 10px;
      height: 10px;
      background-color: #B98A73;
      border-radius: 3px;
      position: absolute;
      top: 1px;
      left: 1px;
      transform: scale(0);
      transition: all 0.2s ease-in-out;
    }
    #login-container .checkbox-container .check-inp-z:checked:after{
      transform: scale(1);
    }
    #login-container .checkbox-container .check-inp-z:hover,.check-inp-z:checked{
      border-color: #B98A73;
    }
    /*按钮组*/
    #login-container .source .btn-list{
      opacity: 0;
      display: flex;
      flex-wrap: nowrap;
      height: 50px;
      padding: 30px 0;
      box-sizing: content-box;
      justify-content: space-between;
    }
    #login-container .source .btn-list .line-gold{
      width: 72px;
      height: 100%;
      border-top: 1px solid #B98A73;
      transform: translateY(50%);
    }
    #login-container .source .btn-list .icon-item{
      padding: 0 20px;
      height: 50px;
      width: 50px;
      box-sizing: content-box;
    }

    #login-container .source .submit-btn{
      background: #B98A73;
      border: none;
      width: 100%;
      border-radius: 4px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }

    #login-container .source .mini-info{
      color: #B98A73;
      opacity: 0.53;
      font-size: 12px;
      text-align: center;
      padding-top: 50px;
    }

    #wx-captcha{
      border-radius: 10px;
    }
    #wx-captcha .wx-captcha-container{
      padding: 20px 10px;
      padding-top: 40px;
      overflow: hidden;
      position: relative;
      min-width: 200px;
    }
    #wx-captcha .captcha-img-box{
      width: 100%;
    }
    #wx-captcha .captcha-img-box img{
      width: 100%;
      background: #fff;
    }
    #wx-captcha .sub-btm{
      width: 100%;
      height: 40px;
      line-height: 40px;
      letter-spacing: 8px;
      background: #B98A73;
      color: #fff;
      margin-top: 10px;
    }
    #wx-captcha .sub-btm.en{
      letter-spacing: 0;
    }
    #wx-captcha .close-btn{
      width: 14px;
      height: 14px;
      position: absolute;
      top:6px;
      right: 6px;
    }
    #wx-captcha .captcha-form{
      width: 100%;
    }
    #wx-captcha .form-input{
      width: 100%;
      margin-top: 10px;
      box-sizing: border-box;
      font-size: 12px;
      padding: 4px 0;
    }
    #wx-captcha .close-btn img{
      width: 100%;
      height: 100%;
    }

    #wx-service .wx-service-container{
      padding: 20px 10px;
      padding-top: 40px;
      overflow: hidden;
      position: relative;
      min-width: 300px;
      min-height: 600px;
    }

    #wx-service .wx-service-container iframe{
      min-width: 300px;
      min-height: 600px;
    }

    #wx-service .close-btn{
      width: 20px;
      height: 20px;
      position: absolute;
      top:6px;
      right: 6px;
    }
    #wx-service .close-btn img{
      width: 100%;
      height: 100%;

    }

    .zh .login-title,.en .login-title{
      font-size: 18px;
      width: 100%;
    }
</style>
