<template>
  <van-popup id="wx-time-picker" v-model="show" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
      <div class="picker-title-contianer">
        <div class="picker-title">{{$t(title)}}</div>
        <div class="picker-mini_title">{{$t(miniTitle)}}</div>
        <div class="close-btn" @click="show = false">
          <img :src="icon.close">
        </div>
      </div>
      <van-picker
        :columns="columns"
        @change="chooseChange"
    ></van-picker>
    <div class="btn-list">
      <div class="submit-btn" @click="submitChoose">{{ $t('common.confirm') }}</div>
    </div>
  </van-popup>
</template>

<script>
import vue from 'vue';
import {Popup, Picker, Toast} from 'vant';
import {strToTime} from "@/config/utils";
vue.use(Picker).use(Popup);
export default {
  name: 'WXTimePicker',
  mounted() {
    this.setColumns();
  },
  updated:function(){
    this.setColumns();
  },
  props: {
    title: {
      type: String,
      default: 'service.select time'
    },
    miniTitle: {
      type: String,
      default: 'service.(local time)'
    },
    pickerShow : {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      icon: {
        close: '/images/icon/if-close 1@3x.png'
      },
      show: false,
      addHours: 4,
      trueTime: '',
      columns: [
        // 第一列
        {
          values: [],
          v: [],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [],
          v: [],
          defaultIndex: 0,
        },
        // 第二列
        {
          values: [],
          v: [],
          defaultIndex: 0,
        },
      ],
      date: {
        values: [],
        v : []
      },
      hours: {
        values: [],
        v : []
      },
      hoursNow : {
        values: [],
        v : []
      },
      minutes: {
        values: [],
        v : []
      },
    }
  },
  watch:{
    pickerShow(now, old){
      this.show = now
    },
    show(val){
      if(val == true){

      }else{
        this.$emit('pickerShowClose',val)
      }
    },
  },
  methods:{
    setColumns(){
      var date = new Date(new Date().getTime() + (this.addHours * 60 * 60 * 1000));
      this.setDate(date.getTime());
      var hours = date.getHours();
      if(date.getMinutes() > 40){
        hours ++;
      }
      this.setHours(hours);
      this.setMinutes();

      this.trueTime = this.columns[0]['v'][0] +' ' + (Array(2).join(0) + this.columns[1]['v'][0]).slice(-2) + ':' + (Array(2).join(0) + this.columns[2]['v'][0]).slice(-2);
    },
    setDate(time){
      var dateList= [];
      var dateListVal= [];
      for (var i = 0; i < 8 ; i++){
        var setTime =time + (i * 3600 * 24 * 1000);
        var date = new Date(setTime);
        var dayText = this.getMonthText(date.getDay());
        var language =  this.$store.getters['local/getLanguages']

        if(language == 'en'){
          dateList.push((date.getMonth() + 1) + '/' + date.getDate() + ' '+ dayText);
        }else{
          dateList.push((date.getMonth() + 1) + '月' + date.getDate() + '日 '+ dayText);
        }
        dateListVal.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
      }
      this.date['values'] = dateList;
      this.date['v'] = dateListVal;

      this.columns[0]['values'] = this.date['values'];
      this.columns[0]['v'] = this.date['v'];
    },
    setHours(hours){
      var hoursList= [];
      var hoursListVal= [];

      var language =  this.$store.getters['local/getLanguages']
      var suf = '点';
      if(language == 'en') suf = ' h';

      for (var i= 0; i< 24; i++){
        hoursList.push(i + suf);
        hoursListVal.push(i);
      }
      this.hours['values'] = hoursList;
      this.hours['v'] = hoursListVal;

      hoursList = [];
      hoursListVal= [];
      for (var i= hours; i< 24; i++){
        hoursList.push(i + suf);
        hoursListVal.push(i);
      }
      this.hoursNow['values'] = hoursList;
      this.hoursNow['v'] = hoursListVal;
      if(this.columns[0]['defaultIndex'] == 0){
        this.columns[1]['values'] = this.hoursNow['values'];
        this.columns[1]['v'] = this.hoursNow['v'];
      }else{
        this.columns[1]['values'] = this.hours['values'];
        this.columns[1]['v'] = this.hours['v'];
      }
    },
    setMinutes(){
      var minutesList= [];
      var minutesListVal= [];

      var language =  this.$store.getters['local/getLanguages']
      var suf = '分';
      if(language == 'en') suf = ' min';

      for (var i= 0; i< 60; i += 5){
        minutesList.push(i + suf);
        minutesListVal.push(i);
      }
      this.minute = minutesList;
      this.minute['values'] = minutesList;
      this.minute['v'] = minutesListVal;


      this.columns[2]['values'] = this.minute['values'];
      this.columns[2]['v'] = this.minute['v'];

    },
    getMonthText(day){
      var language =  this.$store.getters['local/getLanguages']

      if(language == 'zh'){
        switch (day){
          case 1:
            return '星期一';
          case 2:
            return '星期二';
          case 3:
            return '星期三';
          case 4:
            return '星期四';
          case 5:
            return '星期五';
          case 6:
            return '星期六';
          case 0:
            return '星期天';
          default:
            return '';
        }
      }else{
        switch (day){
          case 1:
            return 'Sun. ';
          case 2:
            return 'Mon. ';
          case 3:
            return ' Tues. ';
          case 4:
            return 'Wed. ';
          case 5:
            return 'Thurs. ';
          case 6:
            return 'Fri. ';
          case 0:
            return 'Sat. ';
          default:
            return '';
        }
      }
    },
    chooseChange(picker, value, index) {
      var indexList = picker.getIndexes()
      if(indexList[0] == 0){
        if(index == 0){
          this.columns[1]['values'] = this.hoursNow['values'];
          this.columns[1]['v'] = this.hoursNow['v'];
        }
      }else{
        this.columns[1]['values'] = this.hours['values'];
        this.columns[1]['v'] = this.hours['v'];
      }

      var true_value = this.columns[0]['v'][indexList[0]] +' ' + this.columns[1]['v'][indexList[1]] + ':' + this.columns[2]['v'][indexList[2]];

      this.columns[0]['defaultIndex'] = indexList[0];
      this.columns[1]['defaultIndex'] = indexList[1];
      this.columns[2]['defaultIndex'] = indexList[2];

      this.trueTime = true_value;
    },
    submitChoose(){

      if(this.trueTime == ''){
        this.trueTime = this.columns[0]['v'][0] +' ' + (Array(2).join(0) + this.columns[1]['v'][0]).slice(-2) + ':' + (Array(2).join(0) + this.columns[2]['v'][0]).slice(-2);
      }

      this.trueTime = this.$strToTime(this.trueTime);
      var date = new Date(this.trueTime);

      //触发成功(返回时间戳)
      this.$emit('confirm',date.getTime())
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#wx-time-picker .picker-title-contianer{
  padding: 20px;
  padding-bottom: 0;
  text-align: left;
  position: relative;
  color: #101010;
}
#wx-time-picker .picker-title{
  font-size: 18px;
}
#wx-time-picker .picker-mini_title{
  font-size: 12px;
  color: #DEDBDB;
  padding-top: 4px;
}
#wx-time-picker .close-btn{
  position: absolute;
  width: 24px;
  height: 24px;
  right: 10px;
  top: 10px;
}
#wx-time-picker .close-btn img{
  width: 100%;
  height: 100%;
}
#wx-time-picker .btn-list{
  width: 100%;
  padding-top: 20px;
}
#wx-time-picker .submit-btn{
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  background: #B98A73;
  color: #fff;
  border-radius: 4px;
  margin: auto;
}
</style>
